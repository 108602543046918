export default {
    crEnc(c) {
        return btoa(c);
    },
    crDec(c) {
        if (c == null)
            return null;
        try {
            return atob(c);
        }
        catch
        {
            return c;
        }

    },
    resizeImage(base64Str, maxwidth, maxheight, callback) {
        let img = new Image();
        img.src = base64Str; // Dit is asynchroon
        img.onload = function () {
            let canvas = document.createElement('canvas');
            let MAX_WIDTH = maxwidth;
            let MAX_HEIGHT = maxheight;
            let width = img.width;
            let height = img.height;
            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }
            canvas.width = width;
            canvas.height = height;
            let ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            callback(canvas.toDataURL());
        };
    },

    hexaToRgba(hex) {
        // #FF000080 => rgba(255, 0, 0, 0.5)
        const alphahex = parseInt(hex.substring(7, 9), 16);
        const dec = alphahex / 256.0;
        const r = parseInt(hex.substring(1, 3), 16)
        const g = parseInt(hex.substring(3, 5), 16)
        const b = parseInt(hex.substring(5, 7), 16)
        return 'rgba(' + r + ',' + g + ',' + b + ',' + dec.toString() + ')';
    },
    connected: false
};