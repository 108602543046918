<template>
  <div>
    <v-select
      @input="$emit('input', localValue)"
      v-model="localValue"
      v-bind="$attrs"
      :items="itemsLocal"
      :label="label"
      v-show="!all"
    >
      <template v-slot:prepend-item>
        <v-list-item ripple @click="selectAllClick">
          <v-list-item-action>
            <v-icon v-show="all"> mdi-checkbox-marked </v-icon>
            <v-icon v-show="!all"> mdi-checkbox-blank-outline </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title> {{ textAlle ? textAlle : "(Alles selecteren)" }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    <v-checkbox :label="textAlle ? textAlle : label + ' - Alles geselecteerd'" v-show="all" v-model="all"></v-checkbox>
  </div>
</template>
<script>
export default {
  name: "BobSelect",
  components: {},
  props: {
    value: Array,
    items: Array,
    leegObject: Object,
    alleObject: Object,
    selectAll: Boolean,
    label: String,
    textAlle: String,
  },
  data: () => ({
    localValue: this != null ? this.value : null,
    all: false,
  }),
  methods: {
    selectAllClick: function () {
      this.all = !this.all;
    },
  },
  computed: {
    itemsLocal: function () {
      if (this.leegObject != null)
        return [this.leegObject].concat(this.items);
      else return this.items;
    },
  },
  watch: {
    all: function (newval) {
      if (newval) {
        this.localValue = this.itemsLocal.map((x) => x.Id);
      } else {
        if (this.value.length === this.itemsLocal.length) this.localValue = [];
      }
      this.$emit("input", this.localValue);
    },
    value: function () {
      if (this.value.length < this.itemsLocal.length) {
        this.all = false;
      }
    },
    items: function (newval, oldval) {
      if (oldval.length == 0 && newval.length > 0 && this.selectAll == true)
        this.all = true;
    },
  },
  created() {},
};
</script>

<style scoped></style>
