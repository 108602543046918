<template>
  <v-card outlined v-show="!isLoading">
    <v-card-title><v-icon v-if="kaart" :color="kaart.Kleur" class="mr-1">mdi-card</v-icon><span
        v-show="!$route.params.id"> Invoer nieuw bewijs </span>
      <span v-show="$route.params.id"> Bewijs bewerken </span>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon v-show="kaarttypeId != null && kaart != null && !kaart.Shared"
            color="primary">
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list v-if="kaart">
          <v-list-item @click="archiveerKaart()" v-show="!kaart.IsGearchiveerd">
            <v-list-item-title>Bewijs archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dearchiveerKaart()" v-show="kaart.IsGearchiveerd">
            <v-list-item-title>Bewijs dearchiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteKaart()">
            <v-list-item-title>Bewijs verwijderen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <bob-dropdown :items="kaarttypes" label="Bewijstype" item-value="Id" item-text="Omschrijving" v-model="kaarttypeId"
        v-show="kaarttypeId == null"></bob-dropdown>
      <div v-if="kaart && kaarttypeId">
        <h2 class="mb-2 mt-2">
          {{ kaart.Kaarttype }}
          <span v-show="kaart.IsGearchiveerd"> (gearchiveerd)</span>
        </h2>
        <DatePicker label="Datum" v-model="kaart.Datum"></DatePicker>

        <v-select v-model="kaart.Tags" :items="taglist" chips deletable-chips label="Tags" multiple item-value="Id"
          item-text="Tekst" :append-outer-icon="(Shared ? '' : 'mdi-plus')" @click:append-outer="addTag()"
          :menu-props="{ closeOnClick: true, closeOnContentClick: true }"></v-select>

        <v-textarea filled label="Toelichting" auto-grow v-model="kaart.VrijeTekst" maxlength="500"
          counter="500"></v-textarea>

        <v-form ref="FormVelden" v-model="formVelden" @submit.prevent="saveData()">
          <Veld v-for="(v, i) in ZichtbareVelden" :key="'v' + i" :veldtype="v.Veldtype" :veldnaam="v.VeldNaam"
            :verplicht="v.IsVerplicht" v-model="v.VeldWaarde" candelete="true" :info="v.Info" @delete="deleteVeld(v)"></Veld>
        </v-form>

        <v-btn outlined small @click="showAddVeld">
          <v-icon left>mdi-plus</v-icon> Veld toevoegen</v-btn>
        <v-dialog v-model="addTagDialog">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Nieuwe tag aanmaken
            </v-card-title>
            <v-card-text>
              <v-form class="mt-2" v-on:submit.prevent="saveNewTag" v-if="newTag" ref="formNewTag">
                Nieuwe tags zijn beschikbaar voor alle bewijzen.
                <v-text-field v-model="newTag.Tekst" label="Tag" maxlength="200" autofocus class="mt-2"
                  :rules="[(v) => !!v || 'Vul een tagnaam in.']">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="addTagDialog = false">Annuleren</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="saveNewTag"> Opslaan </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="addVeldDialog">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Veld toevoegen
            </v-card-title>
            <v-card-text>
              <v-form class="mt-2" v-on:submit.prevent="saveNewVeld" v-if="newVeld" ref="formNewVeld">
                <v-text-field label="Veldnaam" v-model="newVeld.VeldNaam" autofocus></v-text-field>
                <v-select label="Soort veld" :items="veldtypes" no-data-text="Geen veldtypes beschikbaar"
                  item-text="Omschrijving" item-value="Code" v-model="newVeld.Veldtype"></v-select>
                <v-checkbox label="Veld verplicht" v-model="newVeld.IsVerplicht" v-show="false"></v-checkbox>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn @click="addVeldDialog = false">Annuleren</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="saveNewVeld"> Opslaan </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <v-alert v-show="error != ''" type="error" text class="mt-5">{{
        error
      }}</v-alert>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="cancel()"> Annuleren </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="saveData()" v-show="!saving && kaart != null">
        Opslaan
      </v-btn>
      <v-progress-circular v-show="saving" indeterminate color="secondary"></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/common/date-picker.vue";
import Veld from "@/components/invoer/Veld.vue";
import BobDropdown from "@/components/common/bob-dropdown.vue";

export default {
  name: "Invoer",
  components: { DatePicker, Veld, BobDropdown },
  data: function () {
    return {
      kaarttypes: [],
      taglist: [],
      kaart: null,
      saving: false,
      isLoading: false,
      kaarttypeId: null,
      addTagDialog: false,
      newTag: null,
      addVeldDialog: false,
      newVeld: null,
      veldtypes: [],
      formVelden: true,
      error: "",
    };
  },
  methods: {
    getLookupFromLocalDb: async function (lookupId) {
      const username = localStorage.getItem("username");
      const sqlcmd = "SELECT * FROM lookup where id = ? AND username = ?";
      const res = await this.$db.query(sqlcmd, [lookupId, username]);
      return JSON.parse(res.values[0].content);
    },
    loadData: async function () {
      this.isLoading = true;
      const online = sessionStorage.getItem("online") == "true";
      if (online) {
        // stamtabellen van server halen
        this.kaarttypes = (await this.$api().Kaart_pKaarttypesGet()).data;
        this.taglist = (await this.$api().Tags_pTagsGet({ KaartId: this.$route.params.id })).data;
        this.veldtypes = (await this.$api().Common_pVeldtypesGet()).data;
      } else {
        // stamtabellen uit lokale db halen
        this.kaarttypes = (await this.getLookupFromLocalDb("bewijstype"));
        this.taglist = (await this.getLookupFromLocalDb("tag"));
        this.veldtypes = (await this.getLookupFromLocalDb("veldtype"));
      }
      if (this.kaarttypes.length == 1) this.kaarttypeId = this.kaarttypes[0].Id;

      if (this.$route.params.id != null) {
        this.kaart = (
          await this.$api().Kaart_pKaartGet({ KaartId: this.$route.params.id })
        ).data[0];
        this.kaarttypeId = this.kaart.KaartTypeId;
      }
      this.isLoading = false;
    },
    saveData() {
      if (!this.$refs.FormVelden.validate()) return false;
      this.saving = true;

      this.error = "";

      this.kaart.KaartTypeId = this.kaarttypeId;
      this.$api()
        .Kaart_pKaartSave(this.kaart)
        .then(() => {
          this.saving = false;
          if (this.$route.params.id) {
            // Bestaande kaart => terug naar readonly
            if (!this.kaart.IsGearchiveerd) {
              this.$router.push({
                name: "kaart",
                params: { id: this.$route.params.id },
              });
            } else {
              // Kaart is gearchiveerd, dan terug naar MijnBewijzen
              this.$router.push({
                name: "mijnbewijzen",
              });
            }
          } else {
            // Nieuwe kaart -> terug naar mijnbewijzen
            this.$router.push({
              name: "mijnbewijzen",
            });
          }
        })
        .catch(() => {
          this.saving = false;
          this.error = "Fout bij opslaan kaart.";
        });
    },
    cancel: function () {
      if (this.kaart == null || this.kaart.Id == null) {
        this.$router.push({
          name: "mijnbewijzen",
        });
      } else {
        this.$router.push({
          name: "kaart",
          params: { id: this.$route.params.id },
        });
      }
    },

    addTag: function () {
      this.newTag = { Id: null, Tekst: "", Gearchiveerd: false, TagTypeId: 1 };
      this.addTagDialog = true;
    },
    saveNewTag: function () {
      if (this.$refs.formNewTag.validate()) {
        this.$api()
          .Tags_pTagSave(this.newTag)
          .then((response) => {
            // Taglijst refreshen
            this.$api()
              .Tags_pTagsGet({ KaartId: this.$route.params.id })
              .then((response) => {
                this.taglist = response.data;
              })
              .catch(() => { });
            // Zojuist aangemaakte tag toevoegen aan geselecteerde tags van de kaart
            if (this.kaart.Tags.indexOf(response.data[0].Id) == -1)
              this.kaart.Tags.push(response.data[0].Id);
            this.addTagDialog = false;
            this.newTag = null;
          });
      }
    },
    saveNewVeld: function () {
      if (this.$refs.formNewVeld.validate()) {
        this.$api()
          .Kaart_pFormatKaartVeldnaam(this.newVeld)
          .then((response) => {
            this.newVeld.VeldNaam = response.data.Veldnaam;
            this.kaart.Velden.push(this.newVeld);
            this.addVeldDialog = false;
          })
          .catch(() => { });
      }
    },
    showAddVeld: function () {
      this.addVeldDialog = true;
      this.newVeld = {
        VeldNaam: "",
        IsVerplicht: false,
        Veldtype: "photo",
        VeldPositie: this.kaart.Velden.length,
        VeldWaarde: "",
        Id: null,
        Deleted: false,
      };
    },
    deleteVeld: function (veld) {
      this.$confirm("Wil je dit veld verwijderen van dit bewijs?").then(
        (res) => {
          if (res) {
            veld.Deleted = true;
          }
        }
      );
    },
    deleteKaart: function () {
      this.$confirm(
        "Weet je zeker dat je dit bewijs definitief wilt verwijderen?"
      ).then((res) => {
        if (res) {
          this.$api()
            .Kaart_pKaartDelete({ KaartId: this.kaart.PubId })
            .then(() => {
              this.$router.push({
                name: "mijnbewijzen",
              });
            })
            .catch(() => { });
        }
      });
    },
    archiveerKaart: function () {
      this.$confirm("Weet je zeker dat je dit bewijs wilt archiveren?").then(
        (res) => {
          if (res) {
            this.kaart.IsGearchiveerd = true;
            this.saveData();
          }
        }
      );
    },
    dearchiveerKaart: function () {
      this.$confirm("Weet je zeker dat je dit bewijs wilt dearchiveren?").then(
        (res) => {
          if (res) {
            this.kaart.IsGearchiveerd = false;
          }
        }
      );
    },
  },
  computed: {
    ZichtbareVelden: function () {
      return this.kaart.Velden.filter((c) => {
        return !c.Deleted;
      });
    },
    Shared: function () {
      if (this.kaart != null)
        return this.kaart.Shared
      else
        return false;
    },
  },
  watch: {
    kaarttypeId: function () {
      if (this.$route.params.id == null && this.kaarttypeId != null) {
        this.$api()
          .Kaart_pKaartInit({ KaarttypeId: this.kaarttypeId })
          .then((response) => {
            this.kaart = response.data;
          })
          .catch(() => { });
      }
    },
  },
  mounted() {
    this.loadData();
  },
  created() { },
};
</script>

<style scoped></style>
