<template>
  <div>
    <p>
      Hieronder zie je hoe het er uit ziet als je een dropdown samenstelt uit
      een extensionpanel met een list. Ik heb er ook wat dummy-tekstvelden
      omheen gezet zodat je ziet hoe dit er op een form uit ziet.
    </p>
    <v-text-field label="Een tekstveld"></v-text-field>

    <bob-dropdown
      :items="dropdownitems"
      label="Een Bob-dropdown"
      item-value="id"
      item-text="naam"
      v-model="vmodel"
      clear
      return-object
    ></bob-dropdown>
    v-model: {{ vmodel }} <br /><br />

    <v-expansion-panels :value="panel" v-show="false">
      <v-expansion-panel>
        <v-expansion-panel-header>{{ placeholder }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item
              v-for="item in dropdownitems"
              :key="item.naam"
              @click="itemclick(item)"
            >
              <v-list-item-content>{{ item.naam }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-text-field label="Nog een tekstveld"></v-text-field>
    <p>Hieronder de v-select zoals we die tot nu toe gebruiken.</p>
    <v-select
      :items="dropdownitems"
      v-model="vmodel"
      item-text="naam"
      item-data="id"
      return-object
    ></v-select>
    <v-btn :to="{ name: 'info' }">Terug</v-btn>
    <v-divider class="mt-3 mb-3"></v-divider>
    Platforminfo Capacitor<br />
    platform = {{ platform }}<br />
    breakpoint = {{ $vuetify.breakpoint.name }}
  </div>
</template>

<script>
import { Capacitor } from "@capacitor/core";
import BobDropdown from "@/components/common/bob-dropdown.vue";

export default {
  name: "TestDropdown",
  components: { BobDropdown },
  data() {
    return {
      panel: null,
      platform: Capacitor.getPlatform(),
      dropdownitems: [
        { id: 1, naam: "Aap" },
        { id: 2, naam: "Beer" },
        {
          id: 3,
          naam: "Cobra",
        },
        {
          id: 4,
          naam: "Das",
        },
        {
          id: 5,
          naam: "Eekhoorn",
        },
        {
          id: 6,
          naam: "Fret",
        },
        {
          naam: "Gorilla",
        },
        {
          id: 7,
          naam: "Haai",
        },
        {
          id: 8,
          naam: "Inktvis",
        },
        {
          id: 9,
          naam: "Jong katje",
        },
        {
          id: 10,
          naam: "Koe",
        },
        {
          id: 11,
          naam: "Lama",
        },
        {
          id: 12,
          naam: "Makke geit",
        },
        {
          id: 13,
          naam: "Neushoorn",
        },
        {
          id: 14,
          naam: "Octopus",
        },
        {
          id: 15,
          naam: "Paap",
        },
        {
          id: 16,
          naam: "Haan",
        },
        {
          id: 17,
          naam: "Ree",
        },
        {
          id: 18,
          naam: "Stier",
        },
        {
          id: 19,
          naam: "Traag dier",
        },
        {
          id: 20,
          naam: "Uil",
        },
        {
          id: 21,
          naam: "Vos",
        },
        {
          id: 22,
          naam: "Wolf",
        },
        {
          id: 23,
          naam: "Xrayfish",
        },
        {
          id: 24,
          naam: "Ysbeer",
        },
        {
          id: 25,
          naam: "Zebra",
        },
      ],
      vmodel: null,
    };
  },
  methods: {
    itemclick: function (item) {
      this.selecteditem = item.naam;
      this.panel = [];
    },
  },
  computed: {
    placeholder: function () {
      if (this.selecteditem == "") return "Maak een keuze";
      else return this.selecteditem;
    },
  },
  mounted() {},
};
</script>

<style>
</style>