import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/useradmin/Login.vue'
import PasswordReset from '@/components/useradmin/PasswordReset.vue'
import MailWebView from '@/components/mail/MailWebView.vue'
import Activate from '@/components/useradmin/Activate.vue'
import Home from '@/components/Home.vue'
import MijnBewijzen from '@/components/mijnbewijzen/MijnBewijzen.vue'
import Rapporten from '@/components/rapporten/Rapporten.vue'
import Instellingen from '@/components/instellingen/Instellingen.vue'
import TagBeheer from '@/components/instellingen/TagBeheer.vue'
import KaarttypeBeheer from '@/components/instellingen/KaarttypeBeheer.vue'
import KaarttypeEdit from '@/components/instellingen/KaarttypeEdit.vue'
import Invoer from '@/components/invoer/Invoer.vue'
import Kaart from '@/components/mijnbewijzen/Kaart.vue'
import Aanmelden from '@/components/useradmin/Aanmelden.vue'
import Archiveren from '@/components/mijnbewijzen/Archiveren.vue'
import Adresboek from '@/components/instellingen/Adresboek.vue'
import AdresboekEdit from '@/components/instellingen/AdresboekEdit.vue'
import Catalogus from '@/components/instellingen/Catalogus.vue'
import CatalogusItem from '@/components/instellingen/CatalogusItem.vue'
import Account from '@/components/profiel/Profiel.vue'
import ProfielWijzigen from '@/components/profiel/ProfielWijzigen.vue'
import Licentie from '@/components/licentie/Licentie.vue'
import LicentieOpzeggen from '@/components/licentie/Opzeggen.vue'
import LicentieKopen from '@/components/licentie/LicentieKopen.vue'
import Info from '@/components/profiel/Info.vue'
import Facturen from '@/components/factuur/Facturen.vue'
import Factuur from '@/components/factuur/Factuur.vue'
import Unsubscribe from '@/components/useradmin/Unsubscribe.vue'
import BedrijfGebruikers from '@/components/bedrijf/gebruikers.vue'
import AlleBewijzen from '@/components/bedrijf/AlleBewijzen.vue'
import BedrijfRappport from '@/components/bedrijf/rapportage/rapporten.vue'
import TestDropdown from '@/components/common/testdropdown.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'mijnbewijzen',
        component: MijnBewijzen,
        props: { archief: false },
        meta: { auth: true }
    },
    {
        path: '/archief',
        name: 'mijnbewijzenarchief',
        component: MijnBewijzen,
        props: { archief: true },
        meta: { auth: true }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { auth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { auth: false }
    },
    {
        path: '/aanmelden',
        name: 'aanmelden',
        component: Aanmelden,
        meta: { auth: false }
    },
    {
        path: '/activeer',
        name: 'activeer',
        component: Activate,
        meta: { auth: false }
    },
    {
        path: '/passwordreset',
        name: 'passwordreset',
        component: PasswordReset,
        meta: { auth: false }
    },
    {
        path: '/mail',
        name: 'mailwebview',
        component: MailWebView,
        meta: { auth: false }
    },
    {
        path: '/invoer',
        name: 'invoer',
        component: Invoer,
        meta: { auth: true, hidenav: true }
    },
    {
        path: '/bewijs/:id',
        name: 'kaart',
        component: Kaart,
        meta: { auth: true }
    },
    {
        path: '/bewerken/:id',
        name: 'bewerken',
        component: Invoer,
        meta: { auth: true, hidenav: true }
    },
    {
        path: '/beheer',
        name: 'instellingen',
        component: Instellingen,
        meta: { auth: true }
    },
    {
        path: '/beheer/tags',
        name: 'tagbeheer',
        component: TagBeheer,
        meta: { auth: true },
        props: { shared: false }
    },
    {
        path: '/bedrijf/beheer/tags',
        name: 'tagbeheerbedrijf',
        component: TagBeheer,
        meta: { auth: true },
        props: { shared: true }
    },
    {
        path: '/beheer/bewijstypebeheer',
        name: 'kaarttypebeheer',
        component: KaarttypeBeheer,
        meta: { auth: true },
        props: { shared: false }
    },
    {
        path: '/bedrijf/beheer/bewijstypes',
        name: 'kaarttypebeheerbedrijf',
        component: KaarttypeBeheer,
        meta: { auth: true },
        props: { shared: true }
    },
    {
        path: '/beheer/bewijstype/:id',
        name: 'kaarttypeedit',
        component: KaarttypeEdit,
        meta: { auth: true }
    },
    {
        path: '/bedrijf/beheer/bewijstype/:id',
        name: 'kaarttypeeditbedrijf',
        component: KaarttypeEdit,
        meta: { auth: true },
        props: { shared: true }
    },
    {
        path: '/beheer/bewijstype',
        name: 'kaarttypenew',
        component: KaarttypeEdit,
        meta: { auth: true }
    },
    {
        path: '/bedrijf/beheer/bewijstype',
        name: 'kaarttypenewbedrijf',
        component: KaarttypeEdit,
        meta: { auth: true },
        props: { shared: true }
    },
    {
        path: '/rapporten',
        name: 'rapporten',
        component: Rapporten,
        meta: { auth: true }
    },
    {
        path: '/archiveren',
        name: 'archiveren',
        component: Archiveren,
        meta: { auth: true }
    },
    {
        path: '/beheer/adresboek',
        name: 'adresboek',
        component: Adresboek,
        meta: { auth: true }
    }, {
        path: '/beheer/adresboek/:id',
        name: 'adresboekedit',
        component: AdresboekEdit,
        meta: { auth: true }
    },
    {
        path: '/beheer/adresboek/nieuw',
        name: 'adresboeknew',
        component: AdresboekEdit,
        meta: { auth: true }
    },
    {
        path: '/catalogus',
        name: 'catalogus',
        component: Catalogus,
        meta: { auth: true }
    },
    {
        path: '/catalogus/:id',
        name: 'catalogusitem',
        component: CatalogusItem,
        meta: { auth: true }
    }, {
        path: '/account',
        name: 'account',
        component: Account,
        meta: { auth: true }
    }, {
        path: '/account/wijzigen',
        name: 'profielwijzigen',
        component: ProfielWijzigen,
        meta: { auth: true }
    }, {
        path: '/licentie',
        name: 'licentie',
        component: Licentie,
        meta: { auth: true }
    }, {
        path: '/opzeggen',
        name: 'licentieopzeggen',
        component: LicentieOpzeggen,
        meta: { auth: true }
    }, {
        path: '/PRO/:code',
        name: 'licentiekopen',
        component: LicentieKopen,
        meta: { auth: false }
    }, {
        path: '/info',
        name: 'info',
        component: Info,
        meta: { auth: true }
    }, {
        path: '/facturen',
        name: 'facturen',
        component: Facturen,
        meta: { auth: true }
    }, {
        path: '/factuur/:factuurnummer',
        name: 'factuur',
        component: Factuur,
        meta: { auth: true }
    }, {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
        meta: { auth: false }
    }, {
        path: '/bedrijf/beheer/gebruikers',
        name: 'bedrijfgebruikers',
        component: BedrijfGebruikers,
        meta: { auth: true, bedrijf: true }
    }, {
        path: '/bedrijf/bewijzen',
        name: 'bedrijfbewijzen',
        component: AlleBewijzen,
        props: { archief: false },
        meta: { auth: true, bedrijf: true }
    }, {
        path: '/bedrijf/bewijzenarchief',
        name: 'bedrijfbewijzenarchief',
        component: AlleBewijzen,
        props: { archief: true },
        meta: { auth: true, bedrijf: true }
    }, {
        path: '/bedrijf/rapportage',
        name: 'bedrijfsrapportage',
        component: BedrijfRappport,
        meta: { auth: true, bedrijf: true }
    }, {
        path: '/bedrijf/beheer/catalogus',
        name: 'bedrijfscatalogus',
        component: Catalogus,
        props: { shared: true, back: 'kaarttypebeheerbedrijf' },
        meta: { auth: true, bedrijf: true }
    },
    {
        path: '/bedrijf/beheer/catalogus/:id',
        name: 'bedrijfscatalogusitem',
        component: CatalogusItem,
        props: { shared: true},
        meta: { auth: true, bedrijf: true }
    },
    {
        path: '/test/dropdown',
        name: 'testdropdown',
        component: TestDropdown,
        meta: { auth: false }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {

    if (to.matched.some(record => record.meta.auth)) {
        
        // Authenticatie vereist. Check of session-cookie nog geldig is.
        var auth = false;
        const online = true; // sessionStorage.getItem("online") == "true";
        if (!online)
        {
            // Gebruiker is offline, dan doorgaan.
            next();
            return;
        }
        router.app.$api().Authenticated() // Vraag server of sessie-id nog geldig is.
            .then((response) => {
                auth = (response.data.length > 0);
                if (auth) {
                    // Sessie-id is nog geldig

                    // Controleer of licentie nog geldig is
                    // Optimalisatie: 1x per 5 minuten checken
                    const checkinterval = 5;
                    let lastcheck = localStorage.getItem("LicentieCheck");
                    var licchecknodig = true;
                    if (lastcheck) {
                        let lastcheckminutes = Math.floor((new Date() - new Date(lastcheck)) / 60000.0);
                        let geldigtot = localStorage.getItem("LicentieTot");
                        if (geldigtot == undefined || geldigtot == "undefined" || geldigtot == null) {
                            licchecknodig = true;
                        } else {
                            if (lastcheckminutes <= checkinterval) {
                                licchecknodig = false;
                            }
                        }
                    }
                    if (licchecknodig && to.name != 'licentie' && to.name != 'profiel' && to.name != 'account' && to.name != 'info' && to.name != 'profielwijzigen' && to.name != 'factuur' && to.name != 'facturen') {
                        router.app.$api()
                            .Licentie_pLicGeldig()
                            .then((response) => {
                                localStorage.setItem("LicentieTot", response.data[0].Tot);
                                localStorage.setItem("LicentieCheck", new Date());
                                localStorage.setItem("LicentieBedrijf", response.data[0].Bedrijf);
                                if (response.data[0].Result)
                                    next();
                                else
                                    next({ name: 'licentie' });
                            })
                            .catch(() => { });
                    } else {
                        next();
                    }
                }
                else {
                    // Sessie-id is verlopen => inlogscherm
                    next({ name: 'login', query: { ReturnUrl: to.fullPath } });
                }
            })
            .catch((e) => {
                // Call naar Authenticated faalt => inlogscherm
                if (e === "Network Error") {
                    // Er is geen netwerk. Dan er vanuit gaan dat sessie nog geldig is
                    next();
                }
                else {
                    next({ name: 'login', query: { ReturnUrl: to.fullPath } });
                }
            });
    }
    else {
        next();
    }
}).bind(this);

export default router
