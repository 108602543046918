<template>
  <v-menu v-model="menu1" :close-on-content-click="false" max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormattedMomentjs"
        :clearable="clearable"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        @click:clear="clear()"
        :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
        @click:append-outer="$emit('delete')"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @change="datepickerchange()"
      :first-day-of-week="1"
      show-week
      locale-first-day-of-year="4"
      locale="nl-nl"
      color="secondary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ["value", "label", "clearable", "candelete"],
  data: function () {
    return {
      date: this.$dayjs().format("YYYY-MM-DD"),
      menu1: false,
    };
  },
  methods: {
    handleInput() {
      let v = this.date;
      if (v == null) v = "";
      this.$emit("input", v);
    },
    datepickerchange() {
      this.menu1 = false;
      this.handleInput();
    },
    clear() {
      this.date = null;
      this.handleInput();
    },
  },
  watch: {
    value: function () {
      if (this.value != null && this.value != "")
        this.date = this.$dayjs(this.value).format("YYYY-MM-DD");
      else this.date = null;
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.date && this.date != ""
        ? this.$dayjs(this.date).format("DD-MM-YYYY")
        : "-";
    },
  },
  created() {
    if (this.value && this.value != "")
      this.date = this.$dayjs(this.value).format("YYYY-MM-DD");
    else this.date = null;
    this.handleInput();
  },
};
</script>