<template>
  <v-card flat v-if="selectie">
    <v-card-title>{{ titel }}</v-card-title>
    <v-card-text>
      <bobselect
        v-model="selectie.Tags"
        :items="taglist"
        chips
        deletable-chips
        label="Tags"
        multiple
        item-value="Id"
        item-text="Tekst"
        hide-details
        :leeg-object="{ Id: -1, Tekst: '(Bewijzen zonder tag)' }"
        text-alle="Alle tags"
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
      >
      </bobselect>
      <v-radio-group
        label="Tag selectiemode"
        row
        v-model="selectie.Mode"
        v-show="!alleTagsSelected"
      >
        <v-radio
          label="en"
          value="AND"
          title="bewijzen die al deze tags hebben"
        ></v-radio>
        <v-radio
          label="of"
          value="OR"
          title="bewijzen die één van deze tags hebben"
        ></v-radio>
      </v-radio-group>
      <div v-show="!alleTagsSelected">
        en = bewijzen die al deze tags hebben<br />
        of = bewijzen die één van deze tags hebben
      </div>
      <bobselect
        v-model="selectie.Bewijstypes"
        :items="bewijstypelist"
        chips
        deletable-chips
        label="Bewijstypes"
        multiple
        item-value="Id"
        item-text="Omschrijving"
        text-alle="Alle bewijstypes"
        select-all
        :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
        class="mt-6 mb-6"
      >
      </bobselect>

      <DatePicker
        label="Vanaf"
        v-model="selectie.PeriodeVanaf"
        v-if="!selectie.AlleDatums"
      ></DatePicker>
      <DatePicker
        label="Tot en met"
        v-model="selectie.PeriodeTm"
        v-if="!selectie.AlleDatums"
      ></DatePicker>
      <v-checkbox
        label="Alle datums selecteren"
        dense
        v-model="selectie.AlleDatums"
      ></v-checkbox>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="verder()">Toon bewijzen</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/common/date-picker.vue";
import bobselect from "@/components/common/bobselect.vue";

export default {
  name: "RapportSelectie",
  components: { DatePicker, bobselect },
  props: ["titel", "bewijzen"],
  model: {
    prop: "bewijzen",
    event: "setBewijzen",
  },
  data: () => ({
    selectie: {
      Tags: [],
      Bewijstypes: [],
      PeriodeVanaf: null,
      PeriodeTm: null,
      AlleDatums: true,
      Mode: "OR",
    },
    taglist: [],
    bewijstypelist: [],
    toonBewijsTypeSelectie: false,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Tags_pTagsInUseGet()
        .then((response) => {
          this.taglist = response.data;
        })
        .catch(() => {});
      this.$api()
        .Kaart_pKaarttypesInUseGet()
        .then((response) => {
          this.bewijstypelist = response.data;
          this.alleBewijstypes = true;
        })
        .catch(() => {});
    },
    concatBewijzen(nwbewijzen) {
      if (this.bewijzenLocal.length == 0) this.bewijzenLocal = nwbewijzen;
      else {
        // Voeg bewijzen toe die nog niet in de lijst staan.
        nwbewijzen.forEach((b) => {
          const bw = this.bewijzenLocal.find((e) => e.PubId == b.PubId);
          if (bw == null) {
            this.bewijzenLocal.push(b);
          } else {
            // Selecteer bewijs als die al in de lijst stond
            if (bw.Checked == false) bw.Checked = true;
          }
        });
      }
    },
    addBewijzen: function (filter) {
      this.$api()
        .Rapporten_pSelectBewijzen(filter)
        .then((response) => {
          //alert(response.data.length.toString() + " bewijzen toevoegen.");
          this.concatBewijzen(response.data);

          this.$emit("verder");
        })
        .catch(() => {});
    },

    verder: function () {
      this.addBewijzen(this.selectie);
    },
  },
  computed: {
    bewijzenLocal: {
      get: function () {
        return this.bewijzen;
      },
      set: function (value) {
        this.$emit("setBewijzen", value);
      },
    },
    alleTagsSelected: function () {
      return this.taglist.length == this.selectie.Tags.length - 1;
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
