/*
    Generated file
    Do not alter.
    05-02-2024 13:39:26
 */
import axios from 'axios';


export default {
    install(Vue) {

        Vue.$api =
        {
            axios: axios,
            apiUrl: process.env.VUE_APP_BASE_URL,
            config: null,
            _updateHeaders: function() {
                Vue.$api.config = {
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + sessionStorage.getItem("session-id")
                    },
                    baseURL: Vue.$api.apiUrl,
                    withCredentials: true,
                };
                Vue.$api.configBlob = {
                    headers: {
                        "content-type": "application/json",
                        "Authorization": "Bearer " + sessionStorage.getItem("session-id")
                    },
                    baseURL: Vue.$api.apiUrl,
                    withCredentials: true,
                    responseType: 'blob',
                };
            }.bind(this),

        }
        Vue.$api._updateHeaders();

        Vue.prototype.$api = function () {
            return {

                updateHeaders: function () {
                    Vue.$api._updateHeaders();
                },
                getBaseUrl: function () {
                    return Vue.$api.apiUrl;
                },
                get: function (url) {
                    return new Promise((resolve, reject) => {
                        Vue.$api.axios.get(url)
                            .then((response) => {
                                if (response.status === 200) {
                                    resolve(response);
                                } else {
                                    reject('Bad request ' + response.code.toString());
                                }
                            })
                            .catch((err) => {
                                if (err.message === 'Network Error') {
                                    this.$root.$emit('offline');
                                    reject('Network Error');
                                }
                                else {
                                    reject('Server error');
                                }
                            });
                    });
                }.bind(this),

    // Adresboek_pAdresDelete
    Adresboek_pAdresDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pAdresDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Adresboek_pAdresInit
    Adresboek_pAdresInit: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pAdresInit' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Adresboek_pAdresSave
    Adresboek_pAdresSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pAdresSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Adresboek_pGet
    Adresboek_pGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Adresboek_pGetEntry
    Adresboek_pGetEntry: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pGetEntry' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Adresboek_pSave
    Adresboek_pSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssAdresboek_pSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Archiveren_pArchiveerKaarten
    Archiveren_pArchiveerKaarten: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssArchiveren_pArchiveerKaarten' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Archiveren_pDearchiveer
    Archiveren_pDearchiveer: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssArchiveren_pDearchiveer' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pBewijzenGet
    Bedrijf_pBewijzenGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pBewijzenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pCatalogusItemAdd
    Bedrijf_pCatalogusItemAdd: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pCatalogusItemAdd' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pDeleteUser
    Bedrijf_pDeleteUser: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pDeleteUser' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pGetUser
    Bedrijf_pGetUser: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pGetUser' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pSaveNewUser
    Bedrijf_pSaveNewUser: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pSaveNewUser' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Bedrijf_pSaveUser
    Bedrijf_pSaveUser: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssBedrijf_pSaveUser' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Common_pAppVersie
    Common_pAppVersie: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssCommon_pAppVersie' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Common_pGetKleuren
    Common_pGetKleuren: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssCommon_pGetKleuren' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Common_pOpschonenFotos
    Common_pOpschonenFotos: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssCommon_pOpschonenFotos' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Common_pUserInfo
    Common_pUserInfo: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssCommon_pUserInfo' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Common_pVeldtypesGet
    Common_pVeldtypesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssCommon_pVeldtypesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Factuur_pFacturenGet
    Factuur_pFacturenGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssFactuur_pFacturenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Factuur_pFactuurGet
    Factuur_pFactuurGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssFactuur_pFactuurGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Factuur_pFactuurVerzendPdf
    Factuur_pFactuurVerzendPdf: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssFactuur_pFactuurVerzendPdf' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pCatalogItemAdd
    Instellingen_pCatalogItemAdd: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pCatalogItemAdd' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pCatalogusGet
    Instellingen_pCatalogusGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pCatalogusGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pCatalogusItemGet
    Instellingen_pCatalogusItemGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pCatalogusItemGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pCatalogusItemRemove
    Instellingen_pCatalogusItemRemove: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pCatalogusItemRemove' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeCatalogus
    Instellingen_pKaarttypeCatalogus: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeCatalogus' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeDelete
    Instellingen_pKaarttypeDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeGet
    Instellingen_pKaarttypeGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeInit
    Instellingen_pKaarttypeInit: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeInit' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeSave
    Instellingen_pKaarttypeSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypesGet
    Instellingen_pKaarttypesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pKaarttypeSharedGet
    Instellingen_pKaarttypeSharedGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pKaarttypeSharedGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pTagsGet
    Instellingen_pTagsGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pTagsGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pTagsGlobalGet
    Instellingen_pTagsGlobalGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pTagsGlobalGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Instellingen_pTagsSharedGet
    Instellingen_pTagsSharedGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssInstellingen_pTagsSharedGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pFormatKaartVeldnaam
    Kaart_pFormatKaartVeldnaam: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pFormatKaartVeldnaam' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pFoto
    Kaart_pFoto: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pFoto' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pFotoSave
    Kaart_pFotoSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pFotoSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaartDelete
    Kaart_pKaartDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaartDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaartGet
    Kaart_pKaartGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaartGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaartGetReadonly
    Kaart_pKaartGetReadonly: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaartGetReadonly' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaartInit
    Kaart_pKaartInit: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaartInit' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaartSave
    Kaart_pKaartSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaartSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaarttypesGet
    Kaart_pKaarttypesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaarttypesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pKaarttypesInUseGet
    Kaart_pKaarttypesInUseGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pKaarttypesInUseGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Kaart_pMijnBewijzenGet
    Kaart_pMijnBewijzenGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssKaart_pMijnBewijzenGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pAnnuleerOpzegging
    Licentie_pAnnuleerOpzegging: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pAnnuleerOpzegging' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pGetLicentieData
    Licentie_pGetLicentieData: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pGetLicentieData' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pGoPro
    Licentie_pGoPro: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pGoPro' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pLicGeldig
    Licentie_pLicGeldig: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pLicGeldig' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pMailEindeDemo
    Licentie_pMailEindeDemo: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pMailEindeDemo' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pMailPro
    Licentie_pMailPro: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pMailPro' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pOpzeggen
    Licentie_pOpzeggen: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pOpzeggen' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Licentie_pSetProRole
    Licentie_pSetProRole: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssLicentie_pSetProRole' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_pGenereerRapport
    Rapporten_pGenereerRapport: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_pGenereerRapport' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_pGenereerRapportSingle
    Rapporten_pGenereerRapportSingle: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_pGenereerRapportSingle' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_pSelectBewijzen
    Rapporten_pSelectBewijzen: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_pSelectBewijzen' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pGenereerRapport
    Rapporten_Bedrijf_pGenereerRapport: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pGenereerRapport' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pGetArchiefAanwezig
    Rapporten_Bedrijf_pGetArchiefAanwezig: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pGetArchiefAanwezig' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pGetSharedBewijstypes
    Rapporten_Bedrijf_pGetSharedBewijstypes: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pGetSharedBewijstypes' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pGetSharedTags
    Rapporten_Bedrijf_pGetSharedTags: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pGetSharedTags' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pGetUsers
    Rapporten_Bedrijf_pGetUsers: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pGetUsers' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Rapporten_Bedrijf_pSelectBewijzen
    Rapporten_Bedrijf_pSelectBewijzen: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssRapporten_Bedrijf_pSelectBewijzen' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Tags_pTagDelete
    Tags_pTagDelete: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssTags_pTagDelete' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Tags_pTagSave
    Tags_pTagSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssTags_pTagSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Tags_pTagsGet
    Tags_pTagsGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssTags_pTagsGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Tags_pTagsInUseGet
    Tags_pTagsInUseGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssTags_pTagsInUseGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pCreateVoorbeeldContent
    UserAdmin_pCreateVoorbeeldContent: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pCreateVoorbeeldContent' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pDeleteGebruikergroep
    UserAdmin_pDeleteGebruikergroep: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pDeleteGebruikergroep' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pGetUsers
    UserAdmin_pGetUsers: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pGetUsers' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pProfielInfo
    UserAdmin_pProfielInfo: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pProfielInfo' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pProfielSave
    UserAdmin_pProfielSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pProfielSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pRegisterNewUser
    UserAdmin_pRegisterNewUser: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pRegisterNewUser' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pResetAccount
    UserAdmin_pResetAccount: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pResetAccount' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserAdmin_pUnsubscribe
    UserAdmin_pUnsubscribe: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssUserAdmin_pUnsubscribe' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Zoeken_pZoekBewijzenBedrijf
    Zoeken_pZoekBewijzenBedrijf: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssZoeken_pZoekBewijzenBedrijf' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Zoeken_pZoekKaarten
    Zoeken_pZoekKaarten: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('ssZoeken_pZoekKaarten' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Login
    Login: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('Login' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Logout
    Logout: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserLogout' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // RequestPasswordReset
    RequestPasswordReset: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('RequestPasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // PasswordReset
    PasswordReset: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('PasswordReset' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // Authenticated
    Authenticated: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pAuthenticated' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // ValidationRulesGet
    ValidationRulesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pValidationRulesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserGetList
    UserGetList: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserGetList' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserInitNew
    UserInitNew: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserInitNew' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserGetDetails
    UserGetDetails: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserGetDetails' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserSave
    UserSave: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserSave' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // UserValidate
    UserValidate: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pUserValidate' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // NewUserMail
    NewUserMail: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('NewUserMail' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // SecurityAreasGet
    SecurityAreasGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pSecurityAreasGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // RoleGetDetails
    RoleGetDetails: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pRoleGetDetails' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),

    // RolesGet
    RolesGet: function(data, frmt) {
        return new Promise((resolve, reject) => {
            let config = Vue.$api.config;
            if (frmt) { if (frmt === "excel" || frmt === "pdf") config = Vue.$api.configBlob; frmt = "?format=" + frmt; } else { frmt = ""; }
            Vue.$api.axios.post('pRolesGet' + frmt, data, config)
                .then((response) => {
                if (response.status === 200) {
                    resolve(response);
                } else {
                    reject('Bad request ' + response.code.toString());
                }
            })
            .catch((err) => {
                if (err.message === 'Network Error') {
                    this.$root.$emit('offline');
                    reject('Network Error');
                }
                else {
                    reject('Server error');
                }
            });
        });
    }.bind(this),



                saveDownload: function(data, filename) {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }.bind(this),

            }
        }
    }
};
