<template>
  <v-card flat v-if="kaarttype">
    <v-card-title><span v-show="!shared">Bewijstype</span><span v-show="shared">Gedeeld bewijstype</span>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="primary">
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list v-if="kaarttype">
          <v-list-item @click="archiveer()" v-show="!kaarttype.IsGearchiveerd">
            <v-list-item-title>Bewijstype archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dearchiveer()" v-show="kaarttype.IsGearchiveerd">
            <v-list-item-title>Bewijstype de-archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="verwijder()" v-show="kaarttype.MagVerwijderen">
            <v-list-item-title>Bewijstype verwijderen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <h3>Kenmerken</h3>
      <v-text-field label="Naam" v-model="kaarttype.Omschrijving" autofocus></v-text-field>
      <v-checkbox label="Foto verplicht" v-model="kaarttype.FotoVerplicht" v-show="false"></v-checkbox>
      <v-select v-model="kaarttype.Tags" :items="avTags" chips deletable-chips label="Standaard tags voor dit bewijstype"
        multiple item-value="Id" item-text="Tekst" messages="Nieuwe bewijzen van dit type krijgen deze tags"></v-select>
      <v-select class="mt-3" label="Bewijskleur" :items="kleuren" item-value="Kleur" item-text="Naam"
        v-model="kaarttype.Kleur"><template slot="item" slot-scope="data">
          <div style="
              width: 20px;
              height: 16px;
              border: 1px solid black;
              border-radius: 2px;
            " :style="{ backgroundColor: data.item.Kleur }" class="mr-2"></div>
          {{ data.item.Naam }}
        </template>
        <template slot="append-outer">
          <div style="
              width: 20px;
              height: 16px;
              border: 1px solid black;
              border-radius: 2px;
            " class="mt-1" :style="{ backgroundColor: kaarttype.Kleur }"></div>
        </template>
      </v-select>
      <v-color-picker v-model="kaarttype.Kleur" dot-size="25" mode="hexa" hide-canvas v-if="false"></v-color-picker>
      <h3 class="mt-1">Standaard onderdelen</h3>
      <ul class="body-1 mt-2">
        <li>Datum</li>
        <li>Tags</li>
        <li>Toelichting</li>
      </ul>
      <p class="caption font-weight-bold mt-2">
        Alle bewijzen hebben deze standaard onderdelen
      </p>
      <h3 class="mt-5">Velden</h3>
      <v-checkbox label="Volgorde velden bewerken" v-model="edtVolgorde" dense hide-details
        v-show="kaarttype.Velden.length > 1"></v-checkbox>
      <v-checkbox label="Extra info gebruiken" v-model="useExtraInfo" dense
        v-show="kaarttype.Velden.length > 0"></v-checkbox>
      <v-data-iterator disable-pagination :items="kaarttype.Velden" hide-default-footer no-data-text="">
        <template v-slot:default="props">
          <v-card v-for="(item, i) in props.items" :key="'v' + i" class="mb-2">
            <v-card-text>
              <v-text-field label="Veldnaam" v-model="item.VeldNaam" append-outer-icon="mdi-delete-outline"
                @click:append-outer="deleteVeld(item)"></v-text-field>
              <v-select label="Soort veld" :items="veldtypes" no-data-text="Geen veldtypes beschikbaar"
                item-text="Omschrijving" item-value="Code" v-model="item.Veldtype"></v-select>
              <v-checkbox label="Veld verplicht" v-model="item.IsVerplicht" v-show="false"></v-checkbox>
              <v-textarea v-if="useExtraInfo" label="Extra info" outlined hide-details v-model="item.Info"></v-textarea>
              <div v-if="edtVolgorde">
                Volgorde: <v-icon v-show="i > 0" title="Verplaats veld omhoog"
                  @click="up(i, item)">mdi-arrow-up-bold-box</v-icon><v-icon v-show="i < kaarttype.Velden.length - 1"
                  title="Verplaats veld omlaag" @click="down(i, item)">mdi-arrow-down-bold-box</v-icon>
              </div>
            </v-card-text>
          </v-card>
        </template>
      </v-data-iterator>
      <v-btn fab small color="secondary" class="mt-2" title="Veld toevoegen" @click="addVeld"><v-icon
          sm>mdi-plus</v-icon></v-btn>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="save">Opslaan</v-btn>
      <v-btn :to="{ name: 'kaarttypebeheer' }" v-if="!shared">Annuleren</v-btn>
      <v-btn :to="{ name: 'kaarttypebeheerbedrijf' }" v-if="shared">Annuleren</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "KaarttypeEdit",
  components: {},
  data: () => ({
    kaarttype: null,
    taglist: [],
    veldtypes: [],
    kleuren: [],
    edtVolgorde: false,
    useExtraInfo: false,
  }),
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loadData: function () {
      if (this.$route.params.id != null) {
        this.$api()
          .Instellingen_pKaarttypeGet({ KaarttypeId: this.$route.params.id })
          .then((response) => {
            this.kaarttype = response.data;
            this.initExtraInfo(this.kaarttype);
          })
          .catch(() => { });
      } else {
        this.$api()
          .Instellingen_pKaarttypeInit({ Shared: this.shared })
          .then((response) => {
            this.kaarttype = response.data;
            this.initExtraInfo(this.kaarttype);
          })
          .catch(() => { });
      }
      this.$api()
        .Tags_pTagsGet()
        .then((response) => {
          this.taglist = response.data;
        })
        .catch(() => { });
      this.$api()
        .Common_pVeldtypesGet()
        .then((response) => {
          this.veldtypes = response.data;
        })
        .catch(() => { });
      this.$api()
        .Common_pGetKleuren()
        .then((response) => {
          this.kleuren = response.data;
        })
        .catch(() => { });
    },
    save: function () {
      this.$api()
        .Instellingen_pKaarttypeSave(this.kaarttype)
        .then(() => {
          if (this.shared)
            this.$router.push({ name: "kaarttypebeheerbedrijf" });
          else
            this.$router.push({ name: "kaarttypebeheer" });
        })
        .catch(() => { });
    },
    addVeld: function () {
      let maxpositie = 0;
      for (let i = 0; i < this.kaarttype.Velden.length; i++)
        if (this.kaarttype.Velden[i].VeldPositie > maxpositie)
          maxpositie = this.kaarttype.Velden[i].VeldPositie;

      this.kaarttype.Velden.push({
        Id: null,
        VeldNaam: "",
        Veldtype: "photo",
        IsVerplicht: false,
        VeldPositie: maxpositie + 1,
        Info: ""
      });
    },
    verwijder: function () {
      this.$confirm("Wil je dit bewijstype verwijderen?").then((res) => {
        if (res) {
          this.$api()
            .Instellingen_pKaarttypeDelete({
              KaarttypeId: this.$route.params.id,
            })
            .then(() => {
              if (this.shared)
                this.$router.push({ name: "kaarttypebeheerbedrijf" });
              else
                this.$router.push({ name: "kaarttypebeheer" });
            })
            .catch(() => { });
        }
      });
    },

    archiveer: async function () {
      const res = await this.$confirm("Wil je dit bewijstype archiveren?");
      if (res) {
        this.kaarttype.IsGearchiveerd = true;
        this.save();
      }
    },
    dearchiveer: async function () {
      const res = await this.$confirm("Wil je dit bewijstype de-archiveren?");
      if (res) {
        this.kaarttype.IsGearchiveerd = false;
        this.save();
      }
    },
    deleteVeld: async function (veld) {
      const res = await this.$confirm(
        "Wil je veld '" +
        veld.VeldNaam +
        "' verwijderen van dit bewijstype? Bestaande bewijzen worden niet gewijzigd."
      );
      if (res) {
        this.kaarttype.Velden.splice(this.kaarttype.Velden.indexOf(veld), 1);
      }
    },
    up: function (idx, item) {
      if (idx <= 0)
        return
      const prev = this.kaarttype.Velden[idx - 1];
      const vp = item.VeldPositie;
      item.VeldPositie = prev.VeldPositie;
      prev.VeldPositie = vp;
      this.kaarttype.Velden = this.kaarttype.Velden.sort((a, b) => a.VeldPositie - b.VeldPositie);
    },
    down: function (idx, item) {
      if (idx >= this.kaarttype.Velden.length)
        return
      const next = this.kaarttype.Velden[idx + 1];
      const vp = item.VeldPositie;
      item.VeldPositie = next.VeldPositie;
      next.VeldPositie = vp;
      this.kaarttype.Velden = this.kaarttype.Velden.sort((a, b) => a.VeldPositie - b.VeldPositie);
    },
    initExtraInfo: function(kaarttype) {
      this.useExtraInfo = kaarttype.Velden.some((x) => (x.Info != null && x.Info != ""))
    }
  },
  watch: {},
  computed: {
    avTags: function () {
      if (this.taglist) {
        if (this.shared)
          return this.taglist.filter((c) => c.TagTypeId != 1);
        else
          return this.taglist;
      }
      else
        return null;
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
