<template>
  <v-card max-width="600px">
    <v-card-text>
        <div v-if="!mail"></div>
        <div v-if="mail" v-html="mail.MailContent">
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MailWebViewer",
  components: {},
  props: [],
  data: () => ({ mail: null }),
  methods: {
    loadData: function () {},
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
