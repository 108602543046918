<template>
  <v-card flat>
    <v-card-title>Voorblad rapport</v-card-title>
    <v-card-text>
      <v-form ref="formVoorblad">
        <v-text-field
          label="Titel rapport"
          v-model="voorbladLocal.titel"
          maxlength="200"
          required
          :rules="[(v) => !!v || 'Vul de titel van het rapport in.']"
        ></v-text-field>
        <DatePicker label="Datum" v-model="voorbladLocal.datum"></DatePicker>
        <v-textarea
          label="Toelichting"
          v-model="voorbladLocal.toelichting"
        ></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="terug()">Terug</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="verder()">Verder</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DatePicker from "@/components/common/date-picker.vue";

export default {
  name: "RapportVoorblad",
  components: { DatePicker },
  props: ["voorblad"],
  data: () => ({}),
  model: {
    prop: "voorblad",
    event: "opslaanVoorblad",
  },
  methods: {
    loadData: function () {},
    verder: function () {
      if (this.$refs.formVoorblad.validate()) {
        this.$emit("verder");
      }
    },
    terug: function () {
      this.$emit("terug");
    },
  },
  computed: {
    voorbladLocal: {
      get: function () {
        return this.voorblad;
      },
      set: function (value) {
        this.$emit("opslaanVoorblad", value);
      },
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
