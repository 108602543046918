<template>
  <v-card flat>
    <v-card-title
      >Adresboek<v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'instellingen' }" plain
        ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-btn color="secondary" class="mt-3 mb-3" :to="{ name: 'adresboeknew' }"
        >Nieuw adres</v-btn
      >
      <v-list-item
        v-for="(a, i) in adressen"
        :key="'a' + i"
        :to="{ name: 'adresboekedit', params: { id: a.Id } }"
      >
        <v-list-item-icon>
          <v-icon>mdi-notebook-edit-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ a.Email }}</v-list-item-title>
          <v-list-item-subtitle>{{ a.Naam }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>
<script >
export default {
  name: "Adresboek",
  components: {},
  data: () => ({ adressen: [] }),
  methods: {
    loadData: function () {
      this.$api()
        .Adresboek_pGet()
        .then((response) => {
          this.adressen = response.data;
        })
        .catch(() => {});
    },
  },
  computed: {},
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
