<template>
  <v-card flat v-if="item">
    <v-card-title><v-icon :color="item.Kleur" class="mr-3">mdi-card-text-outline</v-icon>
      {{ item.Omschrijving }}</v-card-title>
    <v-card-text>
      <span v-html="item.CatalogusTekst"></span>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="{ name: $route.query.returnpath }">Terug</v-btn>
      <v-btn color="secondary" v-if="!item.Checked || shared" @click="add"><v-icon left>mdi-plus</v-icon>Voeg toe</v-btn>
      <v-btn color="secondary" v-if="item.Checked && !shared" @click="remove"><v-icon
          left>mdi-minus</v-icon>Verwijder</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Catalogus",
  components: {},
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    item: null,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Instellingen_pCatalogusItemGet({ PubId: this.$route.params.id })
        .then((response) => {
          this.item = response.data;
        })
        .catch(() => { });
    },
    add: async function () {
      if (!this.shared) {
        // Een normale gebruiker => catalogus-item toevoegen
        this.$api()
          .Instellingen_pCatalogItemAdd({ PubId: this.$route.params.id })
          .then(() => {
            this.$router.push({ name: this.$route.query.returnpath });
          })
          .catch(() => { });
      } else {
        // Een bedrijfsgebruiker: maak een kopie van catalogus-item
        try {
          this.prop = (await this.$api().Bedrijf_pCatalogusItemAdd({ CatalogusPubId: this.$route.params.id })).data;
          this.$router.push({ name: this.$route.query.returnpath });
        } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }

      }
    },
    remove: function () {
      this.$confirm(
        "Weet je zeker dat je dit bewijstype uit je account wilt verwijderen?<br/>Dit heeft geen invloed op al vastgelegde bewijzen."
      ).then((res) => {
        if (res) {
          this.$api()
            .Instellingen_pCatalogusItemRemove({ PubId: this.$route.params.id })
            .then(() => {
              this.$router.push({ name: this.$route.query.returnpath });
            })
            .catch(() => { });
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
