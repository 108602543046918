<template>
  <div>
    <RapportSelectie
      v-show="step == 1"
      titel="Selecteer bewijzen voor archief"
      v-model="bewijzen"
      @verder="verder"
    ></RapportSelectie>
    <GeselecteerdeBewijzen
      v-show="step == 2"
      :bewijzen="bewijzen"
      @verder="step = 3"
      @toevoegen="step = 1"
    ></GeselecteerdeBewijzen>
    <v-card v-if="step == 3">
      <v-card-title>Bewijzen archiveren</v-card-title>
      <v-card-text>
        Weet je zeker dat je {{ selectedBewijzen.length }}
        <span v-show="selectedBewijzen.length === 1">bewijs</span
        ><span v-show="selectedBewijzen.length !== 1">bewijzen</span> wilt
        archiveren?
      </v-card-text>
      <v-card-actions>
        <v-btn :to="{ name: 'mijnbewijzen' }">Annuleren</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="archiveren">Archiveren</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import RapportSelectie from "@/components/rapporten/RapportSelectie.vue";
import GeselecteerdeBewijzen from "@/components/rapporten/GeselecteerdeBewijzen.vue";

export default {
  name: "Rapporten",
  components: { RapportSelectie, GeselecteerdeBewijzen },
  data: () => ({
    step: 1,
    bewijzen: [],
    error: null,
  }),
  methods: {
    loadData: function () {},
    verder: function () {
      this.step++;
    },
    archiveren: function () {
      const onlyId = this.selectedBewijzen.map(({ PubId }) => ({ PubId }));
      this.$api().Archiveren_pArchiveerKaarten(onlyId).then(() => {
        this.$router.push({ name: "mijnbewijzen" });
      }).catch(() => {});
    },
  },
  watch: {},
  computed: {
    selectedBewijzen: function () {
      return this.bewijzen.filter((c) => c.Checked);
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>