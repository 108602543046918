<template>
    <div>
        <RapportSelectie titel="Gezamelijk rapporteren" v-model="rapportparams.bewijzen" v-show="step == 1"
            @verder="selecteerBewijzen"></RapportSelectie>
        <Verzendscherm v-show="step == 2" v-model="rapportparams.voorblad" @verder="verzenden()" @terug="step = 1"
            ref="verzenden"></Verzendscherm>
        <v-alert type="error" text border="top" v-show="error != null">{{
            error
        }}</v-alert>
        <v-snackbar v-model="verzonden" :timeout="3000" top color="success">Rapport is verzonden.</v-snackbar>
    </div>
</template>
  
<script>
import RapportSelectie from "@/components/bedrijf/rapportage/rapportselectie.vue";
import Verzendscherm from "@/components/bedrijf/rapportage/verzenden.vue";

export default {
    name: "Rapporten",
    components: { RapportSelectie, Verzendscherm },
    data: () => ({
        step: 1,
        rapportparams: {
            bewijzen: [],
            voorblad: {
                titel: "",
                datum: new Date(),
                mailnaar: [],
                ccnaarmij: false,
                vanNaam: "",
                toelichting: "",
                mailtext: "",
                van: "",
                archiveren: false,
            },
        },
        error: null,
        verzonden: false,
    }),
    methods: {
        loadData: async function () {
            try {
                let response = (await this.$api().Common_pUserInfo()).data;
                this.rapportparams.voorblad.vanNaam = response.Name;
                this.rapportparams.voorblad.van =
                    response.Name + " <" + response.Email + ">";
                this.rapportparams.voorblad.toelichting =
                    "Dit Bouwbewijs rapport is gemaakt door " + response.Name;
                if (response.Bedrijfsnaam && response.Bedrijfsnaam != "")
                    this.rapportparams.voorblad.toelichting +=
                        " van " + response.Bedrijfsnaam;
                this.rapportparams.voorblad.titel = "Bouwbewijs rapport " + this.$dayjs().format("D-M-YYYY_HHumm");
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }

        },
        selecteerBewijzen: function () {
            this.step++;
        },
        naarVerzendscherm: function () {
            this.setMailtext();
            this.step = 4;
        },
        verzenden: async function () {
            this.error = null;
            this.$refs.verzenden.bezig = true;

            try {
                let response = (await this.$api().Rapporten_Bedrijf_pGenereerRapport(this.rapportparams, "mailpdf")).data;
                this.$refs.verzenden.bezig = false;
                if (!response.Success) {
                    this.error = response.Message;
                } else {
                    // Verzenden succesvol
                    // Adres toevoegen aan adresboek
                    this.$api().Adresboek_pSave({
                        Email: this.rapportparams.voorblad.mailnaar,
                    });
                    // Bewijzen archiveren
                    if (this.rapportparams.voorblad.archiveren) {
                        var archiveren = this.rapportparams.bewijzen
                            .filter((c) => {
                                return c.Checked == true;
                            })
                            .map((c) => {
                                return { PubId: c.PubId };
                            });
                        this.$confirm(
                            "Het rapport is verzonden. De gerapporteerde bewijzen (" +
                            archiveren.length.toString() +
                            ") archiveren?"
                        ).then((res) => {
                            if (res) {
                                this.$api()
                                    .Archiveren_pArchiveerKaarten(archiveren)
                                    .then(() => {
                                        this.$router.push({ name: "bedrijfbewijzen" });
                                    })
                                    .catch(() => { });
                            } else {
                                this.$router.push({ name: "bedrijfbewijzen" });
                            }
                        });
                    } else {
                        this.$router.push({ name: "bedrijfbewijzen" });
                    }
                }
            } catch (ex) {
                this.$refs.verzenden.bezig = false;
                this.error = ex.message;
            }
        },
        terug: function () {
            this.step--;
            this.error = null;
        },
        setMailtext: function () {
            this.rapportparams.voorblad.mailtext =
                "Hierbij ontvang je een rapport van Bouwbewijs.<br/><br/><em>Titel: " +
                this.rapportparams.voorblad.titel +
                "</em><br/>";
            if (
                this.rapportparams.voorblad.toelichting &&
                this.rapportparams.voorblad.toelichting.trim() != ""
            )
                this.rapportparams.voorblad.mailtext +=
                    "<em>Toelichting: " +
                    this.rapportparams.voorblad.toelichting +
                    "</em><br/>";
            this.rapportparams.voorblad.mailtext +=
                '<br/>Met vriendelijke groet,<br/>Bouwbewijs<br/><br/><a href="https://bouwbewijs.nl" target="_blank">bouwbewijs.nl</a>';
        },
    },
    watch: {},
    mounted() {
        this.loadData();
    },
};
</script>
  
<style scoped></style>
  