<template>
  <v-card flat v-if="adres">
    <v-card-title>Adres</v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-text-field
          label="Emailadres"
          v-model="adres.Email"
          maxlength="240"
          required
          :rules="[
            (v) => !!v || 'Vul een emailadres in.',
            (v) =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              'Vul een geldig emailadres in',
          ]"
        ></v-text-field>
        <v-text-field
          label="Naam"
          v-model="adres.Naam"
          maxlength="240"
        ></v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn small @click="verwijder()" v-show="adres.Id"
        >Verwijder adres</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="save">Opslaan</v-btn>
      <v-btn :to="{ name: 'adresboek' }">Annuleren</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AdresEdit",
  components: {},
  data: () => ({
    adres: null,
  }),
  methods: {
    loadData: function () {
      if (this.$route.params.id != null) {
        this.$api()
          .Adresboek_pGetEntry({ AdresboekId: this.$route.params.id })
          .then((response) => {
            this.adres = response.data;
          })
          .catch(() => {});
      } else {
        this.$api()
          .Adresboek_pAdresInit()
          .then((response) => {
            this.adres = response.data;
          })
          .catch(() => {});
      }
    },
    save: function () {
      if (this.$refs.form.validate()) {
        this.$api()
          .Adresboek_pAdresSave(this.adres)
          .then(() => {
            this.$router.push({ name: "adresboek" });
          })
          .catch(() => {});
      }
    },
    verwijder: function () {
      this.$confirm("Wil je dit adres verwijderen?").then((res) => {
        if (res) {
          this.$api()
            .Adresboek_pAdresDelete(this.adres)
            .then(() => {
              this.$router.push({ name: "adresboek" });
            })
            .catch(() => {});
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
