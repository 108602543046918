<template>
    <v-card flat>
        <v-card-title>{{ titel }}</v-card-title>
        <v-card-text>
            <h2>Filters</h2>
            <v-row class="mt-2" v-if="this.filter">
                <v-col cols="2">
                    <h3 class="mb-3">Gebruikers <span v-show="showFilterGebruikers">
                            <v-icon title="Actieve en inactieve gebruikers" v-show="alleenActieveGebruikers" small
                                @click="alleenActieveGebruikers = !alleenActieveGebruikers">mdi-archive-off-outline</v-icon><v-icon
                                title="Alleen actieve gebruikers" v-show="!alleenActieveGebruikers" small
                                @click="alleenActieveGebruikers = !alleenActieveGebruikers">mdi-archive-outline</v-icon>
                        </span></h3>
                    <div>
                        <v-checkbox v-model="selectedAllgebruikers" label="<alle gebruikers>" dense
                            hide-details></v-checkbox>
                        <v-checkbox v-for="g in gebruikersFiltered" v-model="filter.gebruikers" :label="g.Gebruiker"
                            :value="g.Id" :key="g.Id" dense hide-details></v-checkbox>
                    </div>

                </v-col>
                <v-col cols="2">
                    <h3 class="mb-3">Datum</h3>
                    <v-radio-group v-model="filter.datum.mode">
                        <v-radio label="Alle" value="alle"></v-radio>
                        <v-radio label="Periode" value="range"></v-radio>
                        <DatePicker label="Vanaf" v-model="filter.datum.vanaf" v-if="filter.datum.mode == 'range'">
                        </DatePicker>
                        <DatePicker label="Tot en met" v-model="filter.datum.tot" v-if="filter.datum.mode == 'range'">
                        </DatePicker>
                    </v-radio-group>
                </v-col>
                <v-col cols="2">
                    <h3 class="mb-3">Gedeelde tags <span v-show="showFilterTags">
                            <v-icon title="Actieve en inactieve tags" v-show="alleenActieveTags" class="mr-2" small
                                @click="alleenActieveTags = !alleenActieveTags">mdi-archive-off-outline</v-icon><v-icon
                                title="Alleen actieve tags" v-show="!alleenActieveTags" class="mr-2"
                                @click="alleenActieveTags = !alleenActieveTags" small>mdi-archive-outline</v-icon>
                        </span></h3>

                    <div>
                        <v-checkbox v-model="selectedAlltags" label="<alle tags>" dense hide-details></v-checkbox>
                        <v-checkbox v-for="g in tagsFiltered" v-model="filter.tags" :label="g.Tekst" :value="g.Id"
                            :key="g.Id" dense hide-details></v-checkbox>
                    </div>

                    <v-radio-group label="Tag selectiemode" row v-model="filter.tagselectiemode" dense><v-radio dense
                            hide-details label="en" value="AND"></v-radio><v-radio hide-details label="of"
                            value="OR"></v-radio></v-radio-group>

                </v-col>
                <v-col cols="2">
                    <h3 class="mb-3" title="Gedeelde bewijstypes">Bewijstypes <span v-show="showFilterBewijstypes">
                            <v-icon title="Actieve en inactieve bewijstypes" v-show="alleenActieveBewijstypes" class="mr-2"
                                small
                                @click="alleenActieveBewijstypes = !alleenActieveBewijstypes">mdi-archive-off-outline</v-icon><v-icon
                                title="Alleen actieve bewijstypes" v-show="!alleenActieveBewijstypes" class="mr-2" small
                                @click="alleenActieveBewijstypes = !alleenActieveBewijstypes">mdi-archive-outline</v-icon>
                        </span></h3>
                    <div>
                        <v-checkbox v-model="selectedAllbewijstypes" label="<alle bewijstypes>" dense
                            hide-details></v-checkbox>
                        <v-checkbox v-for="g in bewijstypesFiltered" v-model="filter.bewijstypes" :label="g.Omschrijving"
                            :value="g.Id" :key="g.Id" dense hide-details></v-checkbox>
                    </div>

                </v-col>
                <v-col cols="2">
                    <div v-show="showFilterArchief">
                        <h3 class="mb-3">Bewijzenarchief</h3>
                        <v-radio-group v-model="filter.archiefmode">
                            <v-radio value="active" label="zonder gearchiveerde bewijzen"></v-radio>
                            <v-radio value="all" label="met gearchiveerde bewijzen"></v-radio>
                            <v-radio value="archive" label="alleen gearchiveerde bewijzen"></v-radio>
                        </v-radio-group>
                    </div>
                </v-col>
                <v-col cols="2" class="text-right">
                    <v-btn color="secondary" @click="setDefaultFilter">Filters wissen</v-btn>
                </v-col>
            </v-row>
            <v-btn color="secondary" @click="getRapport" class="mt-4">Toon bewijzen</v-btn>

            <v-card v-show="bewijzenLocal.length > 0" flat class="mt-4">
                <v-card-title>Onze bewijzen ({{ selcount }})<v-spacer></v-spacer><v-btn color="secondary"
                        @click="verder()">Rapport maken van
                        deze
                        bewijzen</v-btn></v-card-title>
                <v-card-text>
                    <v-data-iterator disable-pagination :items="bewijzenLocal" hide-default-footer
                        v-show="bewijzenLocal.length > 0" no-data-text="Geen selectie gemaakt">
                        <template v-slot:header>
                            <v-toolbar class="mb-5" v-show="false">
                                <v-text-field v-model="search" clearable flat solo hide-details
                                    prepend-inner-icon="mdi-magnify" label="Zoeken" class="mr-1"
                                    :append-icon="searching ? 'mdi-timer-sand-empty' : ''"></v-text-field>
                            </v-toolbar>
                        </template>

                        <template v-slot:default="props">
                            <v-row>
                                <v-col v-for="item in props.items" :key="item.PubId" cols="12" md="6" xl="4">
                                    <KaartComponent v-model="item.Checked" :navigateTo="null" :kaart="item" class="mb-1">
                                    </KaartComponent>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-iterator>

                </v-card-text>

            </v-card>

        </v-card-text>
    </v-card>
</template>
  
<script>
import DatePicker from "@/components/common/date-picker.vue";
import KaartComponent from "@/components/mijnbewijzen/KaartComponent.vue";
export default {
    name: "Bedrijfsrapport",
    props: ["titel", "bewijzen"],
    model: {
        prop: "bewijzen",
        event: "setBewijzen",
    },
    components: { DatePicker, KaartComponent },
    data: () => ({
        gebruikers: [],
        tags: [],
        bewijstypes: [],
        alleenActieveGebruikers: true,
        alleenActieveTags: true,
        alleenActieveBewijstypes: true,
        filter: null,
        searching: false,
        search: "",
        archiefaanwezig: false,
    }),
    methods: {
        loadData: async function () {
            try {
                this.gebruikers = (await this.$api().Rapporten_Bedrijf_pGetUsers()).data;
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
            try {
                this.tags = (await this.$api().Rapporten_Bedrijf_pGetSharedTags()).data;
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
            try {
                this.bewijstypes = (await this.$api().Rapporten_Bedrijf_pGetSharedBewijstypes()).data;
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
            try {
                this.archiefaanwezig = (await this.$api().Rapporten_Bedrijf_pGetArchiefAanwezig()).data.ArchiefAanwezig;
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }

        },
        setDefaultFilter: function () {
            this.filter = {
                gebruikers: [],
                tags: [],
                allTags: true,
                tagselectiemode: "OR",
                bewijstypes: [],
                allBewijstypes: true,
                archiefmode: "active",
                datum: {
                    mode: "alle",
                    vanaf: null,
                    tot: null,
                }
            };

            this.filter.datum.vanaf = new Date();
            this.filter.datum.vanaf.setDate(1);
            this.filter.datum.vanaf.setMonth(this.filter.datum.vanaf.getMonth() - 1);
            this.filter.datum.tot = new Date();
            this.filter.datum.tot.setDate(0);

            this.selectedAllbewijstypes = true;
            this.selectedAllgebruikers = true;
            this.selectedAlltags = true;
        },
        getRapport: async function () {
            try {
                this.filter.allTags = this.selectedAlltags;
                this.filter.allBewijstype = this.selectedAllbewijstypes;
                this.bewijzenLocal = (await this.$api().Rapporten_Bedrijf_pSelectBewijzen(this.filter)).data;
            } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
        },
        verder: function () {
            this.$emit("verder");
        }

    },
    watch: {
    },
    computed: {
        bewijzenLocal: {
            get: function () {
                return this.bewijzen;
            },
            set: function (value) {
                this.$emit("setBewijzen", value);
            },
        },
        gebruikersFiltered: function () {
            if (this.gebruikers == null)
                return null;
            if (this.alleenActieveGebruikers)
                return this.gebruikers.filter((c) => c.Actief);
            else
                return this.gebruikers;
        },
        tagsFiltered: function () {
            if (this.tags == null)
                return null;
            if (this.alleenActieveTags)
                return this.tags.filter((c) => c.Actief);
            else
                return this.tags;
        },
        bewijstypesFiltered: function () {
            if (this.bewijstypes == null)
                return null;
            if (this.alleenActieveBewijstypes)
                return this.bewijstypes.filter((c) => c.Actief);
            else
                return this.bewijstypes;
        },
        selectedAllgebruikers: {
            set(val) {
                this.filter.gebruikers = []
                if (val) {
                    this.filter.gebruikers = this.gebruikersFiltered.map(x => x["Id"]);
                }
            },
            get() {
                return this.filter.gebruikers.length === this.gebruikersFiltered.length
            }
        },
        selectedAlltags: {
            set(val) {
                this.filter.tags = []
                if (val) {
                    this.filter.tags = this.tagsFiltered.map(x => x["Id"]);
                }
            },
            get() {
                return this.filter.tags.length === this.tagsFiltered.length
            }
        }
        ,
        selectedAllbewijstypes: {
            set(val) {
                this.filter.bewijstypes = []
                if (val) {
                    this.filter.bewijstypes = this.bewijstypesFiltered.map(x => x["Id"]);
                }
            },
            get() {
                return this.filter.bewijstypes.length === this.bewijstypesFiltered.length
            }
        },
        selcount: function () {
            return this.bewijzenLocal.filter((c) => c.Checked).length;
        },
        showFilterGebruikers: function () {
            return this.gebruikers.filter((c) => !c.Actief).length > 0;
        },
        showFilterTags: function () {
            return this.tags.filter((c) => !c.Actief).length > 0;
        },
        showFilterBewijstypes: function () {
            return this.bewijstypes.filter((c) => !c.Actief).length > 0;
        },
        showFilterArchief: function () {
            return this.archiefaanwezig;
        }
    },
    async mounted() {
        await this.loadData();
        this.setDefaultFilter();
    },
};
</script>
  