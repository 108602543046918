var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.licentiedata)?_c('v-card',[_c('v-card-title',[_vm._v("Licentie"),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.licentiedata.IsBedrijfsaccount),expression:"!licentiedata.IsBedrijfsaccount"}],attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down-circle")])],1)]}}],null,false,4173755248)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.facturen()}}},[_c('v-list-item-title',[_vm._v("Facturen")])],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(!_vm.licentiedata.Opgezegd && _vm.licentiedata.IsPro),expression:"!licentiedata.Opgezegd && licentiedata.IsPro"}],on:{"click":function($event){return _vm.opzeggen()}}},[_c('v-list-item-title',[_vm._v("Licentie opzeggen")])],1)],1)],1),_c('v-btn',{attrs:{"icon":"","title":"Terug","to":{ name: 'mijnbewijzen' },"plain":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-close-circle")])],1)],1),_c('v-card-text',[_c('v-list',[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-sign")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Licentietype")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.LicentieType.Omschrijving))])],1)],1),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Email")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.Email))])],1)],1),(
          !_vm.licentiedata.IsBedrijfsaccount &&
          _vm.licentiedata.Betaalwijze &&
          _vm.licentiedata.Betaalwijze.Id &&
          !_vm.licentiedata.Opgezegd &&
          _vm.licentiedata.IsPro
        )?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-currency-eur")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Betaalwijze")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.Betaalwijze.Omschrijving))])],1)],1):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount && _vm.licentiedata.VolgendeIncasso
        ),expression:"\n          !licentiedata.IsBedrijfsaccount && licentiedata.VolgendeIncasso\n        "}]},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Volgende betaling")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.VolgendeIncasso))])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount &&
          _vm.licentiedata.IsPro &&
          !_vm.licentiedata.Opgezegd &&
          _vm.licentiedata.IsAutoIncasso
        ),expression:"\n          !licentiedata.IsBedrijfsaccount &&\n          licentiedata.IsPro &&\n          !licentiedata.Opgezegd &&\n          licentiedata.IsAutoIncasso\n        "}]},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sine-wave")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Betalingsfrequentie")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.Betaalfrequentie.Omschrijving))])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount &&
          _vm.licentiedata.IsPro &&
          !_vm.licentiedata.Opgezegd &&
          _vm.licentiedata.IsAutoIncasso
        ),expression:"\n          !licentiedata.IsBedrijfsaccount &&\n          licentiedata.IsPro &&\n          !licentiedata.Opgezegd &&\n          licentiedata.IsAutoIncasso\n        "}]},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-autorenew")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Automatisch verlengen per")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.licentiedata.VerlengenPer))])],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount &&
          _vm.licentiedata.IsPro &&
          !_vm.licentiedata.Opgezegd &&
          !_vm.licentiedata.IsAutoIncasso
        ),expression:"\n          !licentiedata.IsBedrijfsaccount &&\n          licentiedata.IsPro &&\n          !licentiedata.Opgezegd &&\n          !licentiedata.IsAutoIncasso\n        "}]},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Geldig tot")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.$dayjs(_vm.licentiedata.LicentieTot).format("DD-MM-YYYY"))),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.licentiedata.VerlengdTot),expression:"licentiedata.VerlengdTot"}]},[_vm._v(" (verlengd tot "+_vm._s(_vm.$dayjs(_vm.licentiedata.VerlengdTot).format("DD-MM-YYYY"))+")")])])],1)],1),(!_vm.licentiedata.IsBedrijfsaccount && !_vm.licentiedata.IsPro)?_c('v-list-item',[_c('v-list-item-content',[_vm._v("Een PRO-licentie kost € "+_vm._s(_vm.licentiedata.Tarief.MaandTarief)+" euro per maand of € "+_vm._s(_vm.licentiedata.Tarief.JaarTarief)+" euro per jaar, exclusief BTW.")])],1):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount &&
          !_vm.licentiedata.IsPro &&
          _vm.licentiedata.ProAangeschaftOp == null
        ),expression:"\n          !licentiedata.IsBedrijfsaccount &&\n          !licentiedata.IsPro &&\n          licentiedata.ProAangeschaftOp == null\n        "}]},[_c('v-list-item-content',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.goPro()}}},[_vm._v("Vraag PRO aan")])],1),_c('v-col',{staticClass:"text--secondary"},[_vm._v("Aanvragen is vrijblijvend. Je ontvangt een email met verdere informatie.")])],1)],1)],1),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.licentiedata.IsBedrijfsaccount &&
          _vm.licentiedata.ProAangeschaftOp != null
        ),expression:"\n          !licentiedata.IsBedrijfsaccount &&\n          licentiedata.ProAangeschaftOp != null\n        "}]},[_c('v-list-item-content',[_c('v-row',[_c('v-col',{staticClass:"text--secondary"},[_vm._v("Je hebt een PRO licentie aangevraagd. ")]),_c('v-col',[_c('v-btn',{attrs:{"color":"secondary","link":""},on:{"click":function($event){return _vm.goPro()}}},[_vm._v("Betaallink opnieuw verzenden")])],1)],1)],1)],1),(!_vm.licentiedata.IsBedrijfsaccount && _vm.licentiedata.Opgezegd)?_c('v-list-item',[_c('v-list-item-content',[_vm._v(" Je licentie is opgezegd per "+_vm._s(_vm.$dayjs(_vm.licentiedata.LicentieTot).format("DD-MM-YYYY"))+". ")])],1):_vm._e()],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.licentiedata.IsPro && _vm.mutaties),expression:"licentiedata.IsPro && mutaties"}],attrs:{"color":"secondary"}},[_vm._v("Wijziging opslaan")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.licentiedata.IsBedrijfsaccount && _vm.licentiedata.Opgezegd),expression:"!licentiedata.IsBedrijfsaccount && licentiedata.Opgezegd"}],attrs:{"color":"secondary"},on:{"click":_vm.verlengPro}},[_vm._v("Verleng PRO")]),_c('v-dialog',{model:{value:(_vm.dialogAangevraagd),callback:function ($$v) {_vm.dialogAangevraagd=$$v},expression:"dialogAangevraagd"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" Gelukt ")]),_c('v-card-text',[_vm._v(" Bedankt voor het aanvragen van een PRO licentie. Je ontvangt een email met verdere informatie. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.dialogAangevraagdClick}},[_vm._v(" Ok ")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }