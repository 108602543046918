<template>
  <v-app>
    <v-navigation-drawer app clipped permanent v-if="mode == 'bedrijfsadmin'">
      <v-list nav>

        <v-list-item link :to="{ name: 'bedrijfbewijzen' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Bewijzen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'bedrijfgebruikers' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gebruikersbeheer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'bedrijfsrapportage' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-chart-box</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gezamelijk rapporteren</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'tagbeheerbedrijf' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gedeelde tags</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link :to="{ name: 'kaarttypebeheerbedrijf' }">
          <v-list-item-icon>
            <v-icon color="primary">mdi-card-multiple-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gedeelde bewijstypes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item link @click="switchmode">
          <v-list-item-icon>
            <v-icon color="primary">mdi-swap-horizontal</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Gebruikermodus</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>



    <v-app-bar app light clipped-left color="#FFF" :height="platform == 'ios' ? '90' : '56'"
      :class="{ iosAppbar: platform == 'ios' }">
      <v-btn icon disabled v-if="$route.meta.auth"> </v-btn><!-- tbv centreren logo -->
      <v-spacer></v-spacer>
      <v-img src="/2021_bouwbewiijs_logo.png" max-height="50" max-width="235" contain></v-img>

      <v-spacer></v-spacer>

      <v-menu offset-y bottom right v-if="$route.meta.auth">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-subheader v-if="appinfo">Ingelogd als {{ appinfo.Gebruiker }}</v-subheader>
          <v-list-item :to="{ name: 'account' }" dense>
            <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'licentie' }" dense>
            <v-list-item-icon><v-icon>mdi-file-sign</v-icon></v-list-item-icon>
            <v-list-item-title>Licentie</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'info' }" dense>
            <v-list-item-icon><v-icon>mdi-information-outline</v-icon></v-list-item-icon>
            <v-list-item-title>Over Bouwbewijs</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" dense>
            <v-list-item-icon><v-icon>mdi-exit-to-app</v-icon></v-list-item-icon>
            <v-list-item-title>Uitloggen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-alert v-if="omgeving != ''" color="warning" text icon="mdi-information"> {{ omgeving }}-omgeving</v-alert>
        <v-card color="#bababa" v-if="omgeving == 'Dev'">
          <v-card-title>Test local Db</v-card-title>
          <v-card-text>
            <v-btn @click="saveLookupsLocal" class="mr-2 mb-2" small>Bewaar local data</v-btn> <v-btn @click="showLookup"
              class="mr-2 mb-2" small v-show="tmpLocalData == null || tmpLocalData == ''">Toon
              local data</v-btn>
            <v-btn @click="tmpLocalData = ''" class="mr-2 mb-2" small v-show="tmpLocalData && tmpLocalData != ''">Verberg
              local data</v-btn>
            <v-btn @click="goOnline" class="mr-2 mb-2" small>Reconnect</v-btn>
            <v-textarea label="Local data" multi-line readonly v-model="tmpLocalData"
              v-show="tmpLocalData && tmpLocalData != ''" solo></v-textarea>
          </v-card-text>

        </v-card>
        <router-view />

        <v-dialog v-model="offlineMsg" max-width="400">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Geen verbinding
            </v-card-title>
            <v-card-text class="mt-2">
              Er is geen verbinding met de server mogelijk of er is iets anders
              mis. Mogelijk ben je offline. Probeer deze actie opnieuw als je
              online bent.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="offlineMsg = false">
                sluiten
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>

    <v-bottom-navigation color="secondary" grow light app :min-height="platform == 'ios' ? 80 : null" v-if="$route.meta.auth &&
      ($route.meta.hidenav == null || $route.meta.hidenav == false) && mode == 'gebruiker'
      ">
      <v-btn :to="{ name: 'mijnbewijzen' }" exact>
        <span>Mijn bewijzen</span>
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'rapporten' }">
        <span>Rapporteren</span>
        <v-icon>mdi-chart-box</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'instellingen' }">
        <span>Beheer</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn @click="switchmode" v-if="this.platform == 'web' && mode == 'gebruiker' && appinfo.Bedrijfsadmin">
        <span>Bedrijfsbeheer</span>
        <v-icon>mdi-swap-horizontal</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { Capacitor } from "@capacitor/core";
import { Network } from '@capacitor/network';
import { CapacitorSQLite, SQLiteConnection } from "@capacitor-community/sqlite";
import {
  defineCustomElements as jeepSqlite,
  applyPolyfills,
} from "jeep-sqlite/loader";

applyPolyfills().then(() => {
  jeepSqlite(window);
});

export default {
  name: "App",

  data: () => ({
    omgeving: process.env.VUE_APP_OMGEVING_INFO,
    appinfo: { Gebruiker: "onbekend" },
    platform: Capacitor.getPlatform(),
    offlineMsg: false,
    refreshmode: 0,
    tmpLocalData: ""
  }),
  methods: {
    getAppInfo: async function () {
      try {
        const online = sessionStorage.getItem("online") == "true";
        if (!online) {
          // Gebruiker is offline, appinfo-data lokaal bepalen.
          this.appinfo = { Gebruiker: "Offline", Bedrijfsaccount: false, Bedrijfsadmin: false, Versie: process.env.VUE_APP_VERSION };
          return;
        }
        let response = (await this.$api().Common_pAppVersie()).data;
        if (response == null || response.length == 0)
          await this.$confirm("Geen geldige licentie gevonden.", { buttonFalseText: "Sluiten", buttonTrueText: null });
        this.appinfo = response[0];
        this.appinfo.Versie = process.env.VUE_APP_VERSION
      } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
    },
    switchmode: function () {
      if (this.mode == "gebruiker") {
        localStorage.setItem("bedrijfsmode", "bedrijfsadmin")
        this.$router.push({ name: "bedrijfbewijzen" });
      }
      else {
        localStorage.setItem("bedrijfsmode", "gebruiker");
        this.$router.push({ name: "mijnbewijzen" });
      }
      this.refreshmode++;
    },
    logout: function () {
      sessionStorage.removeItem("session-id");
      localStorage.removeItem("bouwbewijs-auth");
      this.$api().updateHeaders();
      this.$router.push({ name: "aanmelden" });
      this.getAppInfo();
    },
    appOffline: function () {
      this.offlineMsg = true;
    },
    initDb: async function () {
      const platform = Capacitor.getPlatform();
      const sqlite = new SQLiteConnection(CapacitorSQLite);

      if (platform === "web") {
        // Create the 'jeep-sqlite' Stencil component
        const jeepSqlite = document.createElement("jeep-sqlite");
        document.body.appendChild(jeepSqlite);
        await customElements.whenDefined("jeep-sqlite");
        // Initialize the Web store
        await sqlite.initWebStore();
      }

      const ret = await sqlite.checkConnectionsConsistency();
      //console.log(`after checkConnectionsConsistency ${ret.result}`);
      const isConn = (await sqlite.isConnection("db_bouwbewijs")).result;
      //console.log(`after isConnection ${isConn}`);
      let db;
      if (ret.result && isConn) {
        db = await sqlite.retrieveConnection("db_bouwbewijs");
      } else {
        db = await sqlite.createConnection("db_bouwbewijs", false, "no-encryption", 1);
      }
      //      console.log(`after create/retrieveConnection ${JSON.stringify(db)}`);

      await db.open();
      //      console.log(`db.open()`);
      const query_create_lookup = `
      CREATE TABLE IF NOT EXISTS lookup (
        id TEXT PRIMARY KEY NOT NULL,
        content TEXT NOT NULL,
        created TIMESTAMP NOT NULL DEFAULT CURRENT_TIMESTAMP,
        version TEXT NOT NULL,
        username TEXT NOT NULL
      )
      `
      await db.execute(query_create_lookup);
      Vue.prototype.$db = db;
    },
    saveLookupsLocal: async function () {
      const online = sessionStorage.getItem("online") == "true";
      if (!online) {
        console.log("saveLookupsLocal niet van toepassing indien offline.");
        return; // Als we niet online zijn dan heeft dit geen zin.
      }
      const username = localStorage.getItem("username");

      // Sla stamtabellen lokaal op:
      // Tags
      try {
        const tags = (await this.$api().Tags_pTagsGet()).data;
        const sqlcmd = "INSERT OR REPLACE INTO lookup(id, content, version, username) VALUES (?,?,?,?)";
        const values = ["tag", JSON.stringify(tags), '999-ABC-999', username];
        await this.$db.run(sqlcmd, values);

      } catch (ex) { console.log("Fout bij ophalen tags", ex); }
      // Bewijstypes
      try {
        const bewijstypes = (await this.$api().Kaart_pKaarttypesGet()).data;
        const sqlcmd = "INSERT OR REPLACE INTO lookup(id, content, version, username) VALUES (?,?,?,?)";
        const values = ["bewijstype", JSON.stringify(bewijstypes), '888-ABC-888', username];
        await this.$db.run(sqlcmd, values);
      } catch (ex) { console.log("Fout bij ophalen bewijstypes", ex); }
      // Veldtypes
      try {
        const veldtypes = (await this.$api().Common_pVeldtypesGet()).data;
        const sqlcmd = "INSERT OR REPLACE INTO lookup(id, content, version, username) VALUES (?,?,?,?)";
        const values = ["veldtype", JSON.stringify(veldtypes), '888-ABC-888', username];
        await this.$db.run(sqlcmd, values);
      } catch (ex) { console.log("Fout bij ophalen veldtypes", ex); }
      //await this.$db.closeConnection("db_bouwbewijs");
    },
    showLookup: async function () {
      const sqlcmd = "SELECT id, version, created, username FROM lookup";
      const res = await this.$db.query(sqlcmd);
      this.tmpLocalData = JSON.stringify(res);
    },
    goOnline: function () {
      const online = sessionStorage.getItem("online") == "true";
      if (!online) {
        alert("Je bent nog offline, reconnecten lukt niet.");
        return;
      }

      let lsCred = JSON.parse(
        this.$helpers.crDec(localStorage.getItem("bouwbewijs-auth"))
      );

      if (lsCred) {
        this.$api()
          .Login({ Username: lsCred.username, Password: lsCred.password })
          .then((response) => {
            // Inloggen gelukt 
            sessionStorage.setItem("session-id", response.data.Token);
            localStorage.setItem("username", lsCred.username);
            this.$api().updateHeaders();
            this.$root.$emit("login");
            // Is licentie nog geldig? 
            this.$api()
              .Licentie_pLicGeldig()
              .then((response) => {
                if (response.data[0].Result) this.redirect(); // Licentie is geldig 
                else this.$router.push({ name: "licentie" }); // Licentie is verlopen => naar licentiepagina 
              })
              .catch(() => { });
          })
          .catch(() => {
            alert("Inloggen misluk. Wachtwoord inmiddels gewijzigd? (doorsturen naar loginscherm)");
          });
      } else {
        alert("Geen locale credentials aanwezig, automatisch inloggen gaat niet (doorsturen naar loginscherm)");
      }
    }

  },
  computed: {
    mode: function () {
      this.refreshmode;
      if (this.platform == null || this.platform != "web")
        return "gebruiker"; // op mobile altijd gebruikersmode
      if (this.appinfo == null || !this.appinfo.Bedrijfsadmin)
        return "gebruiker"; // Als je geen bedrijfsadmin bent dan altijd gebruikersmode
      const localstoragemode = localStorage.getItem("bedrijfsmode");
      if (localstoragemode == null || localstoragemode == "")
        return "bedrijfsadmin"; // Als er geen localstorage-waarde is vastgelegd en je bent admin dan altijd admin
      return localstoragemode; // Waarde uit localstorage pakken
    },
  },
  watch: {
  },
  mounted: async function () {
    sessionStorage.setItem("online", (await Network.getStatus()).connected);
    Network.addListener('networkStatusChange', status => {
      //sessionStorage.setItem("online", status.connected)
      sessionStorage.setItem("onlineStatus", status.connected);
      sessionStorage.setItem("online", true); // Nug nog geen ondersteuning voor offline.
    });

    this.getAppInfo();
    this.$root.$on("login", this.getAppInfo);
    this.$root.$on("logout", this.getAppInfo);
    this.$root.$on("offline", this.appOffline);

    await this.initDb();
  },
};
</script>

<style>
.v-application {
  font-family: "Fira Sans", sans-serif !important;
}

.iosAppbar {
  padding-top: 15px;
}

/* Fix voor bug dat in production build de knoppen voor bottom-navigation on top worden uitgelijnd. */
.v-item-group.v-bottom-navigation .v-btn {
  height: inherit !important;
}

.v-card__text,
.v-card__title {
  word-break: normal !important;
}
</style>