<template>
  <v-card flat>
    <v-card-title>Catalogus<v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: back }" plain><v-icon
          color="primary">mdi-close-circle</v-icon></v-btn></v-card-title>

    <v-card-text>
      <v-list>
        <div v-for="(cc, i1) in catalogus" :key="'c' + i1">
          <v-subheader>{{ cc.Categorie }}</v-subheader>
          <v-list-item v-for="(c, i2) in cc.Items" :key="'i' + i2"
            :to="{ name: shared ? 'bedrijfscatalogusitem' : 'catalogusitem', params: { id: c.PubId }, query: { returnpath: shared ? 'bedrijfscatalogus' : 'catalogus' } }">
            <v-list-item-icon>
              <v-icon color="green" class="mr-1" v-show="c.Checked && !shared">mdi-check-bold</v-icon>
              <v-icon color="white" class="mr-1" v-show="!c.Checked || shared">mdi-check</v-icon>
              <v-icon :color="c.Kleur">mdi-card-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ c.Omschrijving }}</v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-card-text>
    <v-card-actions> </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Catalogus",
  components: {},
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
    back: {
      type: String,
      default: "kaarttypebeheer",
    },
  },
  data: () => ({
    catalogus: [],
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Instellingen_pCatalogusGet()
        .then((response) => {
          this.catalogus = response.data;
        })
        .catch(() => { });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
