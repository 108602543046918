<template>
  <div>
    <v-label class="mb-0 pb-0">{{ veldnaam }} </v-label> <v-icon v-show="info && info != ''" color="#189CD9" style="position: relative; top:-2px" @click="showInfo(info)">mdi-information</v-icon>
    <v-text-field
      v-model="veldwaardeLocal"
      v-if="veldtype == 'text'"
      :readonly="veldreadonly"
      maxlength="250"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      class="mt-0 pt-0"
    ></v-text-field>

    <v-text-field
      v-model="veldwaardeLocal"
      v-if="veldtype == 'int'"
      type="text"
      pattern="[0-9]*"
      inputmode="numeric"
      :readonly="veldreadonly"
      maxlength="10"
      class="mt-0 pt-0"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      :rules="[(v) => /^\-?[0-9]{0,10}$/.test(v) || 'Vul een geheel getal in']"
    />
    <v-text-field
      v-model="veldwaardeLocal"
      class="mt-0 pt-0"
      v-if="veldtype == 'decimal'"
      type="text"
      pattern="[0-9]*"
      inputmode="decimal"
      :readonly="veldreadonly"
      maxlength="20"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      :rules="[
        (v) =>
          /^(\-?[0-9]{1,9}([\.,][0-9]{1,9})?)?$/.test(v) ||
          'Vul een kommagetal in',
      ]"
    />

    <v-text-field
      v-model="veldwaardeLocal"
      class="mt-0 pt-0"
      v-if="veldtype == 'percentage'"
      type="text"
      pattern="[0-9]*"
      inputmode="decimal"
      suffix=" %"
      :readonly="veldreadonly"
      maxlength="20"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      :rules="[
        (v) =>
          /^(\-?[0-9]{1,9}([\.,][0-9]{1,9})?)?$/.test(v) ||
          'Vul een kommagetal in',
      ]"
    />

    <v-select
      v-model="veldwaardeLocal"
      :items="['Ja', 'Nee']"
      class="mt-0 pt-0"
      v-if="veldtype == 'list_ja-nee'"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      :readonly="veldreadonly"
    ></v-select>

    <v-select
      v-model="veldwaardeLocal"
      :items="['Ja', 'Nee', 'N.v.t.']"
      class="mt-0 pt-0"
      v-if="veldtype == 'list_ja-nee-nvt'"
      :append-outer-icon="candelete ? 'mdi-delete-outline' : ''"
      @click:append-outer="deleteClick()"
      :readonly="veldreadonly"
    ></v-select>

    <DatePicker
      class="mt-0 pt-0"
      v-model="veldwaardeLocal"
      v-if="veldtype == 'date' && !veldreadonly"
      :candelete="candelete"
      :clearable="true"
      @delete="deleteClick()"
    ></DatePicker>
    <v-text-field
      :value="datetostr(veldwaardeLocal)"
      :label="veldnaam"
      v-if="veldtype == 'date' && veldreadonly"
      :readonly="true"
    />

    <div v-if="veldtype == 'photo'">
      <v-row v-if="platform != 'web' && !readonly">
        <v-col>
          <v-btn @click="neemFoto('CAMERA')"
            ><v-icon left>mdi-camera</v-icon>Foto maken
          </v-btn>
        </v-col>
        <v-col align="right">
          <v-btn @click="neemFoto('PHOTOS')"
            ><v-icon left>mdi-film</v-icon>Album
          </v-btn>
        </v-col>
      </v-row>
      <v-file-input
        accept="image/*"
        label="Foto uploaden"
        outlined
        v-model="fileinputbinary"
        prepend-icon="mdi-camera"
        v-if="platform == 'web' && !readonly"
      ></v-file-input>
      <v-progress-linear
        v-show="uploading"
        indeterminate
        color="secondary"
      ></v-progress-linear>
      <v-row v-if="candelete" no-gutters>
        <v-col class="text-right"
          ><v-btn icon @click="deleteClick()"
            ><v-icon>mdi-delete-outline</v-icon></v-btn
          ></v-col
        >
      </v-row>
      <v-carousel
        class="mb-2"
        v-show="zichtbareFotos && zichtbareFotos.length > 0"
        v-model="fotoidx"
        :show-arrows="zichtbareFotos.length > 1"
        :continuous="false"
        height="400px"
        :hide-delimiter-background="true"
      >
        <v-carousel-item
          v-for="(img, i) in zichtbareFotos"
          :key="'f' + i"
          style="background-color: #f0f0f0"
        >
          <v-img
            :src="
              $api().getBaseUrl() +
              '/ssKaart_pFoto?format=binary&PubId=' +
              img.PubId
            "
            contain
            width="auto"
            height="100%"
          >
            <v-btn
              icon
              v-show="img.DefaultFoto && !readonly"
              title="Standaard foto"
              @click="maakDefault(img)"
              ><v-icon color="secondary">mdi-star</v-icon></v-btn
            >
            <v-btn
              icon
              v-show="!img.DefaultFoto && !readonly"
              title="Maak standaard foto"
              @click="maakDefault(img)"
              ><v-icon color="secondary">mdi-star-outline</v-icon></v-btn
            >
            <v-btn
              icon
              title="Verwijder foto"
              @click="deletePhoto(img)"
              class="ml-3"
              v-show="!readonly"
              ><v-icon color="secondary">mdi-delete</v-icon></v-btn
            ></v-img
          ></v-carousel-item
        >
      </v-carousel>
    </div>
    <v-dialog v-model="infoDialog.show" max-width="500"> 
      <v-card> 
        <v-card-title>{{ infoDialog.title }}</v-card-title> 
        <v-card-text><span v-html="infoDialog.text"></span></v-card-text> 
        <v-card-actions> 
          <v-spacer></v-spacer> <v-btn @click="infoDialog.show = false">Sluiten</v-btn> 
        </v-card-actions> 
      </v-card> 
    </v-dialog> 
  </div>
</template>
<script>
import DatePicker from "@/components/common/date-picker.vue";
import { Camera, CameraResultType } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
export default {
  name: "Veld",
  components: { DatePicker },
  props: [
    "veldtype",
    "veldnaam",
    "verplicht",
    "veldwaarde",
    "readonly",
    "candelete",
    "info"
  ],
  data: () => ({
    veldreadonly: false,
    fileinputbinary: null,
    platform: Capacitor.getPlatform(),
    fotoidx: null,
    uploading: false,
    infoDialog: {
      show: false,
      info: "",
      title: "Info"
    }
  }),
  model: {
    prop: "veldwaarde",
    event: "veldwaardeChange",
  },
  methods: {
    loadData: function () {
      this.veldreadonly = this.readonly == true;
    },

    datetostr: function (d) {
      if (d == null || d == "") return "-";
      const s = d.split("-");
      if (s.length == 3) return s[2] + "-" + s[1] + "-" + s[0];
      else return d;
    },
    deleteClick: function () {
      this.$emit("delete");
    },
    neemFoto: function (source) {
      if (!source) source = "CAMERA";
      const takePicture = async () => {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          source: source,
          width: 2000,
          preserveAspectRatio: true,
        });

        // image.webPath will contain a path that can be set as an image src.
        // You can access the original file using image.path, which can be
        // passed to the Filesystem API to read the raw data of the image,
        // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
        //var imageUrl = image.webPath;
        this.uploading = true;
        let fotodata =
          "data:image/" + image.format + ";base64," + image.base64String;
        var self = this;
        this.$helpers.resizeImage(fotodata, 1000, 1000, function (thumbnail) {
          self
            .$api()
            .Kaart_pFotoSave({ FotoData: fotodata, Thumbnail: thumbnail })
            .then((response) => {
              let id = response.data.PubId;
              self.addFoto(id);
              self.uploading = false;
            })
            .catch(async (e) => {
              self.uploading = false;
              await self.$confirm(
                "Fout bij resizen van foto: " + e.toString(),
                {
                  buttonFalseText: "Sluiten",
                  buttonTrueText: null,
                }
              );
              return;
            });
        });
      };
      takePicture();
    },

    maakDefault: function (foto) {
      let json = this.getVeldwaardeObj();
      for (const f of json) {
        f.DefaultFoto = f.PubId == foto.PubId;
      }
      this.setVeldwaardeObj(json);
    },

    deletePhoto: function (img) {
      this.$confirm("Wil je deze foto verwijderen?").then((res) => {
        if (res) {
          let json = this.getVeldwaardeObj();
          let filtered = json.filter((f) => {
            return f.PubId !== img.PubId;
          });

          // Als je de default-foto hebt weggegooid dan nieuwe default maken.
          if (img.DefaultFoto && json.length > 0) {
            json[0].DefaultFoto = true;
          }

          this.setVeldwaardeObj(filtered);
        }
      });
    },
    getVeldwaardeObj: function () {
      if (this.veldwaardeLocal == null || this.veldwaardeLocal.length == 0) {
        return JSON.parse("[]");
      }

      return JSON.parse(this.veldwaardeLocal);
    },
    setVeldwaardeObj: function (jsonvalue) {
      this.veldwaardeLocal = JSON.stringify(jsonvalue);
    },
    addFoto: function (id) {
      let j = this.getVeldwaardeObj();
      let newphoto = {
        PubId: id,
        DefaultFoto: j.length == 0,
        Volgnummer: j.length,
      };
      j.push(newphoto);
      this.setVeldwaardeObj(j);
      this.fotoidx = j.length - 1;
    },
    showInfo: async function (info) {
      info = info.replaceAll("\n", "<br/>");
      // Remove tags behalve <br />
      this.infoDialog.text = info.replaceAll(/<(?!br\s*\/?)[^>]+>/g, '');
      this.infoDialog.title = this.veldnaam;
      this.infoDialog.show = true;
    },
  },
  watch: {
    // Er wordt een nieuwe foto geupload (web)
    fileinputbinary: function () {
      this.uploading = true;
      var reader = new FileReader();
      reader.readAsDataURL(this.fileinputbinary);
      reader.onload = (e) => {
        var fotodata = e.target.result;
        var self = this;
        this.$helpers.resizeImage(fotodata, 1000, 1000, function (thumbnail) {
          // Resize ook de oorspronkelijke afbeelding
          self.$helpers.resizeImage(fotodata, 2000, 2000, function (org) {
            self
              .$api()
              .Kaart_pFotoSave({ FotoData: org, Thumbnail: thumbnail })
              .then((response) => {
                let id = response.data.PubId;
                self.addFoto(id);
                self.uploading = false;
              })
              .catch(async (e) => {
                self.uploading = false;
                await self.$confirm(
                  "Fout bij resizen van foto: " + e.toString(),
                  {
                    buttonFalseText: "Sluiten",
                    buttonTrueText: null,
                  }
                );
                return;
              });
          });
        });
      };
    },
  },
  computed: {
    zichtbareFotos: function () {
      if (this.veldtype == "photo") {
        if (!this.veldwaardeLocal || this.veldwaardeLocal.length == 0)
          return [];
        else return JSON.parse(this.veldwaardeLocal);
      } else return [];
    },
    veldwaardeLocal: {
      get: function () {
        return this.veldwaarde;
      },
      set: function (value) {
        this.$emit("veldwaardeChange", value);
      },
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
