<template>
  <v-card flat>
    <v-card-title>Versturen rapport</v-card-title>
    <v-card-subtitle
      >Van de geselecteerde bewijzen wordt een pdf-rapport gegenereerd. Dit
      rapport wordt als bijlage per email verzonden.</v-card-subtitle
    >
    <v-card-text>
      <v-form ref="formVerzenden">
        <v-combobox
          label="Aan (emailadres)"
          type="email"
          v-model="voorbladLocal.mailnaar"
          ref="aan"
          validate-on-blur
          :items="adresboek"
          item-text="Email"
          item-value="Email"
          chips
          multiple
          deletable-chips
          solo
          :delimiters="[',', ';', ' ']"
          :return-object="false"
          hide-selected
          :menu-props="{ closeOnClick: true, closeOnContentClick: true }"
          :error-messages="aanErrors"
          :error="error"
        ></v-combobox>
        <v-text-field
          label="Van"
          readonly
          v-model="voorbladLocal.van"
        ></v-text-field>
        <v-checkbox
          label="Stuur een kopie naar mijzelf (cc)"
          v-model="voorbladLocal.ccnaarmij"
        ></v-checkbox>
        <v-text-field
          label="Onderwerp"
          readonly
          v-model="onderwerp"
        ></v-text-field>
        <v-alert color="secondary" text>
          Hierbij ontvang je een rapport van Bouwbewijs.
          <br /><br />
          <em>Titel: {{ voorblad.titel }}</em>
          <br />
          <span v-show="toelichtingNewLine && toelichtingNewLine.trim() != ''"
            ><em>Toelichting: {{ toelichtingNewLine }}</em
            ><br
          /></span>
          <br /><a href="https://bouwbewijs.nl" target="_blank"
            >bouwbewijs.nl</a
          >
        </v-alert>
        <v-checkbox
          label="Geselecteerde bewijzen gelijk archiveren"
          v-model="voorbladLocal.archiveren"
        ></v-checkbox>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="terug()">terug</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="verzenden()" v-show="!bezig"
        >Rapport verzenden</v-btn
      >
      <v-progress-circular
        v-show="bezig"
        indeterminate
        color="secondary"
      ></v-progress-circular>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "RapportVerzenden",
  components: {},
  props: ["voorblad"],
  data: () => ({ adresboek: [], bezig: false }),
  model: {
    prop: "voorblad",
    event: "opslaanVoorblad",
  },
  methods: {
    loadData: function () {
      this.$api()
        .Adresboek_pGet()
        .then((response) => {
          this.adresboek = response.data;
        })
        .catch(() => {});
    },
    verzenden: function () {
      if (!this.error && this.$refs.formVerzenden.validate()) {
        this.$emit("verder");
      }
    },
    terug: function () {
      this.$emit("terug");
    },
  },
  computed: {
    voorbladLocal: {
      get: function () {
        return this.voorblad;
      },
      set: function (value) {
        this.$emit("opslaanVoorblad", value);
      },
    },
    onderwerp: function () {
      return this.voorbladLocal.titel;
    },
    toelichtingNewLine: function () {
      if (this.voorblad.toelichting) return this.voorblad.toelichting;
      //.replace(/(?:\r\n|\r|\n)/g, '<br/>');
      else return null;
    },
    aanErrors: function () {
      const errors = [];
      if (
        !this.voorbladLocal.ccnaarmij &&
        this.voorbladLocal.mailnaar.length === 0
      )
        errors.push("Vul een emailadres in...");
      return errors;
    },
    error: function () {
      return (
        !this.voorbladLocal.ccnaarmij &&
        this.voorbladLocal.mailnaar.length === 0
      );
    },
  },
  watch: {
    /*"voorbladLocal.ccnaarmij": function (val) {
      if (this.voorbladLocal.mailnaar.length === 0) this.$refs.aan.error = !val;
      else this.$refs.aan.error = false;
      if (this.$refs.aan.error)
        this.$refs.aan.errorMessages = ["Vul een emailadres in..."];
      else this.$refs.aan.errorMessages = [];
    },*/
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
