<template>
  <v-container>
    <v-card v-if="$route.query.token">
      <v-card-title>Activeer je account voor Bouwbewijs</v-card-title>
      <v-card-text>
        Welkom bij Bouwbewijs
        <v-text-field label="Gebruikersnaam" disabled v-model="$route.query.loginname"
          prepend-icon="mdi-account-circle"></v-text-field>

        <v-text-field label="Nieuw wachtwoord" type="password" v-model="newpw" maxlength="100" autofocus
          prepend-icon="mdi-lock"></v-text-field>

        <v-text-field label="Herhaal wachtwoord" type="password" v-model="newpwr" maxlength="100" autofocus
          prepend-icon="mdi-lock"></v-text-field>

        <v-alert outlined type="error" text v-if="errormessage">{{
          errormessage
        }}</v-alert>
        <v-alert outlined type="warning" text v-if="message">{{ message }}</v-alert>
        <v-alert outlined type="info" text v-if="success">Het wachtwoord is opgeslagen. Klik
          <router-link to="/Login">hier</router-link> om in te loggen.
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="text-end">
          <v-btn type="submit" @click="reset()" color="secondary" v-if="!success">Account activeren</v-btn>
          <div class="text-caption mt-2">Door het account te activeren ga je akkoord met de <a href="https://bouwbewijs.nl/gebruiksvoorwaarden/"
              target="_blank">gebruiksvoorwaarden</a>.</div>
        </div>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Activate",
  data() {
    return {
      email: "",
      errormessage: "",
      message: "",
      newpw: "",
      newpwr: "",
      success: false,
      resetvalid: true,
      mailverzonden: false,
    };
  },
  methods: {
    loaddata: function () { },
    requestReset: function () {
      if (this.$refs.formreset.validate()) {
        this.$api()
          .RequestPasswordReset({ Email: this.email })
          .then((response) => {
            if (response.data == "Ok") {
              this.mailverzonden = true;
            } else {
              alert(response.data);
            }
          })
          .catch(() => {
            alert("Activeren mislukt.");
          });
      }
    },
    reset: function () {
      if (this.newpw == this.newpwr) {
        this.$api()
          .PasswordReset({
            Token: this.$route.query.token,
            NewPassword: this.newpw,
          })
          .then((response) => {
            if (response.data == "Ok") {
              this.success = true;
              this.message = "";
              this.errormessage = "";
            } else {
              this.success = false;
              this.errormessage = "Activeren is mislukt. Mogelijk is de uitnodiging verlopen.";
              this.message = "";
            }
          })
          .catch((e) => {
            this.success = false;
            this.errormessage = "Activeren is mislukt. " + e;
            this.message = "";
          });
      } else {
        this.success = false;
        this.errormessage = "Wachtwoord en herhaald wachtwoord zijn niet gelijk.";
        this.message = "";
      }
    },
  },
  created: function () {
    this.loaddata();
  },
};
</script>

<style scoped>
.login-error {
  color: red;
}
</style>
