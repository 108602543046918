<template>
  <v-card flat>
    <v-card-title>Beheer</v-card-title>
    <v-card-text>
      <v-list-item :to="{ name: 'tagbeheer' }">
        <v-list-item-icon>
          <v-icon>mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tags</v-list-item-title>
          <v-list-item-subtitle
            >Tags toevoegen of verwijderen</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'kaarttypebeheer' }">
        <v-list-item-icon>
          <v-icon>mdi-card-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Bewijstypes</v-list-item-title>
          <v-list-item-subtitle>Bewijstypes maken en wijzigen</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'adresboek' }">
        <v-list-item-icon>
          <v-icon>mdi-notebook-edit-outline </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Adresboek</v-list-item-title>
          <v-list-item-subtitle>Adresboek beheren</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{ name: 'mijnbewijzenarchief', query: { terug: 'instellingen'} }">
        <v-list-item-icon>
          <v-icon>mdi-safe</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Bewijzenarchief</v-list-item-title>
          <v-list-item-subtitle>Bekijk gearchiveerde bewijzen</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Instellingen",
  components: {},
  data: () => ({}),
  methods: {
    loadData: function () {},
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
