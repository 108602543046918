<template>
  <div>
    <v-card-title
      ><span v-show="!archief">Mijn bewijzen</span
      ><span v-show="archief">Bewijzenarchief</span>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            v-show="!archief"
            color="primary"
          >
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'archiveren' }" v-show="!archief">
            <v-list-item-title>Bewijzen archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'mijnbewijzenarchief' }" v-show="!archief">
            <v-list-item-title>Naar bewijzenarchief</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        icon
        v-show="archief"
        title="Archief sluiten"
        :to="{
          name: $route.query.terug ? $route.query.terug : 'mijnbewijzen',
        }"
        color="primary"
        ><v-icon>mdi-close-circle</v-icon></v-btn
      >
    </v-card-title>
    <v-skeleton-loader
      max-width="300"
      type="card"
      v-show="loading"
    ></v-skeleton-loader>
    <v-btn
      :to="{ name: 'invoer' }"
      color="secondary"
      class="mb-6 mt-2"
      v-show="!archief"
      ><v-icon left>mdi-plus</v-icon> Nieuw bewijs maken</v-btn
    >
    <v-data-iterator
      disable-pagination
      :items="kaarten"
      hide-default-footer
      v-show="!loading"
      :no-data-text="
        archief ? 'Nog geen bewijzen gearchiveerd' : 'Nog geen bewijzen gemaakt'
      "
    >
      <template v-slot:header>
        <v-toolbar class="mb-5">
          <v-text-field
            v-model="search"
            clearable
            flat
            solo
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Zoeken"
            class="mr-1"
            :append-icon="searching ? 'mdi-timer-sand-empty' : ''"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            cols="12"
            md="6"
            xl="4"
          >
            <KaartComponent navigateTo="kaart" :kaart="item"></KaartComponent>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-btn
      color="secondary"
      :to="{ name: $route.query.terug ? $route.query.terug : 'mijnbewijzen' }"
      v-show="archief"
      class="mt-2"
      >Archief sluiten</v-btn
    >
  </div>
</template>

<script>
import KaartComponent from "@/components/mijnbewijzen/KaartComponent.vue";
export default {
  name: "MijnBewijzen",
  components: { KaartComponent },
  props: ["archief"],
  data: () => ({
    kaarten: [],
    //sortBy: "Datum",
    //sortDesc: true,
    //keys: ["Kaarttype", "Datum"],
    search: "",
    searching: false,
    loading: false,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Kaart_pMijnBewijzenGet({ Archief: this.archief })
        .then((response) => {
          this.kaarten = response.data;
          this.loading = false;
          this.searching = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    searchBewijzen: function () {
      this.searching = true;
      if (this.search.trim() == "") {
        this.loadData();
      } else {
        this.$api()
          .Zoeken_pZoekKaarten({
            SearchTerm: this.search,
            Archief: this.archief,
          })
          .then((response) => {
            this.kaarten = response.data;
            this.loading = false;
            this.searching = false;
          })
          .catch(() => {});
      }
    },
  },
  watch: {
    archief: function () {
      this.loading = true;
      this.loadData();
    },
    search: function () {
      this.searchBewijzen();
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
