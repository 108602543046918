<template>
  <v-card outlined v-if="!loading && kaart" :style="{
    borderLeftColor: leftBorderColor,
    borderLeftWidth: leftBorderWidth,
  }">
    <v-card-title>{{ kaart.Kaarttype }}<v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon color="primary" v-show="!kaart.Shared">
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="mailrapport">
            <v-list-item-title>Mail mij dit bewijs als pdf</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn icon title="Terug" @click="terug" plain><v-icon
          color="primary">mdi-close-circle</v-icon></v-btn></v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" :to="{ name: 'bewerken', params: { id: $route.params.id } }" v-if="!kaart.IsGearchiveerd">
        Bewerken
      </v-btn>
      <v-btn color="secondary" @click="dearchiveren" v-if="kaart.IsGearchiveerd">
        Dearchiveren
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-text-field label="Datum" readonly :value="$dayjs(kaart.Datum).format('DD-MM-YYYY')"></v-text-field>
      <v-chip v-for="(tag, i) in kaart.Tags" :key="'t' + i" class="mr-1 mb-1">
        {{ tag.Tekst }}
      </v-chip>
      <v-textarea filled label="Toelichting" auto-grow v-model="kaart.VrijeTekst" readonly></v-textarea>

      <Veld v-for="(v, i) in kaart.Velden" :key="'v' + i" :veldtype="v.Veldtype" :veldnaam="v.VeldNaam"
        :verplicht="v.IsVerplicht" v-model="v.VeldWaarde" :readonly="true"></Veld>
      <v-dialog v-model="busymail" persistent max-width="500"><v-card><v-card-title>Mail
            verzenden</v-card-title><v-card-text><v-progress-circular indeterminate></v-progress-circular> Mail wordt
            verzonden</v-card-text> </v-card></v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import Veld from "@/components/invoer/Veld.vue";

export default {
  name: "Kaart",
  components: { Veld },
  data: function () {
    return {
      kaart: null,
      loading: false,
      busymail: false,
    };
  },
  methods: {
    loadData: function () {
      this.loading = true;
      this.$api()
        .Kaart_pKaartGetReadonly({ KaartId: this.$route.params.id })
        .then((response) => {
          this.kaart = response.data;
          this.loading = false;
        })
        .catch(() => { });
    },
    dearchiveren: function () {
      this.$api()
        .Archiveren_pDearchiveer({ KaartId: this.kaart.PubId })
        .then(() => {
          this.$router.push({ name: "mijnbewijzenarchief" });
        })
        .catch(() => { });
    },
    terug: function () {
      if (this.kaart.Shared) {
        if (this.kaart.IsGearchiveerd) {
          this.$router.push({ name: "bedrijfbewijzenarchief" });
        } else {
          this.$router.push({ name: "bedrijfbewijzen" });
        }
      } else {
        if (this.kaart.IsGearchiveerd) {
          this.$router.push({ name: "mijnbewijzenarchief" });
        } else {
          this.$router.push({ name: "mijnbewijzen" });
        }
      }
    },
    mailrapport: async function () {
      try {
        this.busymail = true;
        await this.$api().Rapporten_pGenereerRapportSingle(
          { KaartId: this.kaart.PubId },
          "mailpdf"
        );
        this.busymail = false;
        await this.$confirm("Dit bewijs is per mail verzonden.", {
          buttonFalseText: "Sluiten",
          buttonTrueText: null,
        });
      } catch (ex) {
        this.busymail = false;
        await this.$confirm("Fout: " + ex.toString(), {
          buttonFalseText: "Sluiten",
          buttonTrueText: null,
        });
      }
    },
  },
  computed: {
    leftBorderColor: function () {
      if (this.kaart.Kleur == "#FFFFFF") return "#E0E0E1";
      else return this.kaart.Kleur;
    },
    leftBorderWidth: function () {
      if (this.kaart.Kleur == "#FFFFFF") return "1px";
      else return "5px";
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
