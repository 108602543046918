<template>
  <v-card v-if="profieldata">
    <v-card-title
      >Account

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-show="!profieldata.IsBedrijfsaccount" v-bind="attrs" v-on="on" icon color="primary">
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :to="{ name: 'profielwijzigen' }">
            <v-list-item-title>Gegevens wijzigen</v-list-item-title>
          </v-list-item>
          <v-list-item @click="resetAccount()">
            <v-list-item-title>Account leeg maken</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteAccount()">
            <v-list-item-title>Account verwijderen</v-list-item-title>
          </v-list-item>
        </v-list> </v-menu
      ><v-btn
        icon
        title="Terug"
        :to="{ name: 'mijnbewijzen' }"
        plain
        color="primary"
        ><v-icon>mdi-close-circle</v-icon></v-btn
      >
    </v-card-title>
    <v-card-text v-if="profieldata">
      <v-list>
        <v-list-item>
          <v-list-item-icon
            ><v-icon>mdi-office-building-outline</v-icon></v-list-item-icon
          >
          <v-list-item-content
            ><v-list-item-title>Bedrijfsnaam</v-list-item-title>
            <v-list-item-subtitle>{{
              profieldata.Bedrijf
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-icon
            ><v-icon>mdi-account-circle</v-icon></v-list-item-icon
          >
          <v-list-item-content
            ><v-list-item-title>Naam</v-list-item-title>
            <v-list-item-subtitle>{{
              profieldata.Naam
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item
          ><v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>{{
              profieldata.Email
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
      </v-list>
      <v-list-item>
        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
        <v-list-item-content
          ><v-list-item-title>Datum aanmaken account</v-list-item-title>
          <v-list-item-subtitle>{{
            profieldata.Aanmelddatum
          }}</v-list-item-subtitle></v-list-item-content
        >
      </v-list-item>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Profiel",
  components: {},
  props: [],
  data: () => ({ profieldata: null }),
  methods: {
    loadData: function () {
      this.$api()
        .UserAdmin_pProfielInfo()
        .then((response) => {
          this.profieldata = response.data;
        })
        .catch(() => {});
    },
    deleteAccount: function () {
      this.$confirm(
        "Weet je zeker dat je je account en al je gegevens bij Bouwbewijs wilt verwijderen?",
        { title: "Let op", color: "error", icon: "mdi-alert" }
      ).then((res) => {
        if (res) {
          this.$confirm(
            "Let op, dit kan niet ongedaan gemaakt worden.<br/> Weet je zeker dat je <b>al je gegevens</b> bij Bouwbewijs wilt <b>verwijderen</b>?",
            { title: "Let op", color: "error", icon: "mdi-alert" }
          ).then((res2) => {
            if (res2) {
              this.$api()
                .UserAdmin_pDeleteGebruikergroep()
                .then(() => {
                  this.$router.push({ name: "aanmelden" });
                })
                .catch(() => {});
            }
          });
        }
      });
    },
    resetAccount: function () {
      this.$confirm(
        "Weet je zeker dat je je account leeg wilt maken? Al je bewijzen, bewijstypes en je adresboek worden verwijderd.",
        { title: "Let op", color: "error", icon: "mdi-alert" }
      ).then((res) => {
        if (res) {
          this.$confirm(
            "Let op, dit kan niet ongedaan gemaakt worden.<br/> Weet je zeker dat je je account wilt leegmaken?",
            { title: "Let op", color: "error", icon: "mdi-alert" }
          ).then((res2) => {
            if (res2) {
              this.$api()
                .UserAdmin_pResetAccount()
                .then(() => {
                  this.$router.push({ name: "mijnbewijzen" });
                })
                .catch(() => {});
            }
          });
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
