<template>
  <div>
    <v-card flat v-if="step == 1">
      <v-card-title>Welkom bij Bouwbewijs</v-card-title>
      <v-card-text>
        <v-card elevation="2" outlined class="mb-4" @click="inloggenBestaand">
          <v-card-title>Inloggen</v-card-title>
        </v-card>
        <v-card elevation="2" outlined class="mb-4" @click="aanmelden">
          <v-card-title>Nieuw account aanmaken</v-card-title>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card flat v-if="step == 2">
      <v-card-title>Aanmelden bij Bouwbewijs</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-text-field label="Bedrijfsnaam" v-model="user.Bedrijf" prepend-icon="mdi-office-building-outline" autofocus
            class="mb-2" maxlength="200"></v-text-field>
          <v-text-field label="Naam*" v-model="user.Naam" prepend-icon="mdi-account-circle" class="mb-2" maxlength="200"
            required :rules="[(v) => !!v || 'Vul je naam in.']"></v-text-field>
          <v-text-field ref="email" type="email" label="Emailadres*" v-model="user.Email" prepend-icon="mdi-email"
            class="mb-2" maxlength="200" validate-on-blur required autocomplete="username" :rules="[
              (v) => !!v || 'Vul je emailadres in.',
              (v) =>
                /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                'Vul geldig emailadres in',
            ]"></v-text-field>
          <v-text-field ref="Wachtwoord" label="Wachtwoord*" type="password" v-model="user.Wachtwoord"
            prepend-icon="mdi-lock" class="mb-2" maxlength="200" required validate-on-blur autocomplete="new-password"
            :rules="[
              (v) => !!v || 'Vul een wachtwoord in',
              (v) => /^.{8,}$/.test(v) || 'Minimaal 8 posities',
            ]"></v-text-field>
          <v-text-field ref="HerhaalWachtwoord" label="Herhaal wachtwoord*" type="password"
            v-model="user.WachtwoordHerhaal" prepend-icon="mdi-lock" class="mb-2" maxlength="200" required
            autocomplete="new-password"></v-text-field>
          <v-checkbox v-model="akkoord" :rules="[
            (v) =>
              !!v ||
              'Je kunt alleen een account aanmaken indien je akkoord gaat met de voorwaarden.',
          ]">
            <div slot="label">
              Ik ga akkoord met de
              <a href="https://www.bouwbewijs.nl/gebruiksvoorwaarden" target="_blank" @click.stop>gebruiksvoorwaarden</a>
            </div>
          </v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="step = 1">Annuleren</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="accountAanmaken">Account aanmaken</v-btn>
      </v-card-actions>
    </v-card>

    <v-card flat v-if="step == 3">
      <v-card-title>Aanmelden bij Bouwbewijs</v-card-title>
      <v-card-text>
        <v-form ref="formBestaandAccount" v-model="validBestaandAccount">
          Inloggen met bestaand account
          <v-text-field ref="emailBestaand" label="Emailadres*" type="email" v-model="userBestaand.Email"
            prepend-icon="mdi-email" class="mb-2" maxlength="200" autofocus autocomplete="username" required
            :rules="[(v) => !!v || 'Vul je emailadres in.']"></v-text-field>
          <v-text-field label="Wachtwoord*" type="password" v-model="userBestaand.Wachtwoord" prepend-icon="mdi-lock"
            class="mb-2" maxlength="200" required autocomplete="current-password" :rules="[
              (v) => !!v || 'Vul een wachtwoord in',
              (v) => /^.{8,}$/.test(v) || 'Minimaal 8 posities',
            ]"></v-text-field>
          <v-btn text small :to="{ name: 'passwordreset' }">Wachtwoord vergeten</v-btn>
          <v-alert color="error" text v-show="bestaandInloggenMislukt">Inloggen mislukt</v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="step = 1">Annuleren</v-btn>
        <v-btn color="secondary" @click="inloggenBestaandAccount">Inloggen</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { Capacitor } from "@capacitor/core";

export default {
  name: "Aanmelden",
  components: {},
  props: [],
  data: () => ({
    step: 1,
    user: {
      Naam: "",
      Bedrijf: "",
      Email: "",
      Wachtwoord: "",
      WachtwoordHerhaal: "",
    },
    userBestaand: {
      Email: "",
      Wachtwoord: "",
    },
    valid: true,
    validBestaandAccount: true,
    bestaandInloggenMislukt: false,
    akkoord: false,
  }),
  methods: {
    loadData: function () {
      // Uitloggen
      sessionStorage.removeItem("session-id");
      localStorage.removeItem("bouwbewijs-auth");
      this.$api().updateHeaders();
    },
    aanmelden: function () {
      this.step = 2;
    },
    accountAanmaken: function () {
      if (this.$refs.form.validate()) {
        if (this.user.Wachtwoord == this.user.WachtwoordHerhaal) {
          this.$api()
            .UserAdmin_pRegisterNewUser(this.user)
            .then((response) => {
              var result = response.data;
              if (result.Success) {
                // Gelukt
                localStorage.setItem(
                  "bouwbewijs-auth",
                  this.$helpers.crEnc(
                    JSON.stringify({
                      username: result.Username,
                      password: result.Password,
                    })
                  )
                );
                sessionStorage.setItem("session-id", result.Token);
                this.$api().updateHeaders();
                this.$root.$emit("login");
                this.toStartPage();
              } else {
                // Mislukt
                if (result.Field == "email") {
                  this.$refs.email.error = true;
                  this.$refs.email.errorMessages = result.Message;
                }
              }
            })
            .catch(() => { });
        } else {
          // Herhaalwachtwoord niet correct
          this.$refs.HerhaalWachtwoord.error = true;
          this.$refs.HerhaalWachtwoord.errorMessages =
            "Herhaald wachtwoord is anders dan wachtwoord.";
        }
      }
    },
    inloggenBestaand: function () {
      this.step = 3;
    },
    inloggenBestaandAccount: function () {
      if (this.$refs.formBestaandAccount.validate()) {
        this.bestaandInloggenMislukt = false;
        this.$api()
          .Login({
            Username: this.userBestaand.Email,
            Password: this.userBestaand.Wachtwoord,
          })
          .then((response) => {
            // Inloggen gelukt
            localStorage.setItem(
              "bouwbewijs-auth",
              this.$helpers.crEnc(
                JSON.stringify({
                  username: this.userBestaand.Email,
                  password: this.userBestaand.Wachtwoord,
                })
              )
            );
            localStorage.setItem("username", this.userBestaand.Email);
            sessionStorage.setItem("session-id", response.data.Token);
            this.$api().updateHeaders();
            this.$root.$emit("login");
            this.toStartPage();
          })
          .catch(() => {
            this.bestaandInloggenMislukt = true;
          });
      }
    },

    toStartPage: async function () {
      const online = sessionStorage.getItem("online") == "true";
      if (Capacitor.getPlatform() != "web")
        this.$router.push({ name: "mijnbewijzen" });
      if (!online) {   // Niet online, dan nooit naar bedrijfsmode
        localStorage.removeItem("bedrijfsmode");
        this.$router.push({ name: "mijnbewijzen" });
      }
      let appinfo = (await this.$api().Common_pAppVersie()).data[0];
      if (!appinfo.Bedrijfsadmin)
        localStorage.removeItem("bedrijfsmode");
      const modelocstor = localStorage.getItem("bedrijfsmode");
      if (modelocstor != null) {
        if (modelocstor == "bedrijfsadmin")
          this.$router.push({ name: "bedrijfbewijzen" });
        else
          this.$router.push({ name: "mijnbewijzen" });
      }
      else {
        if (appinfo.Bedrijfsadmin)
          this.$router.push({ name: "bedrijfbewijzen" });
        else
          this.$router.push({ name: "mijnbewijzen" });
      }
    }
  },
  watch: {
    "user.Email": function () {
      this.$refs.email.error = false;
      this.$refs.email.errorMessages = "";
    },
    "user.WachtwoordHerhaal": function () {
      this.$refs.HerhaalWachtwoord.error = false;
      this.$refs.HerhaalWachtwoord.errorMessages = "";
    },
    "user.Wachtwoord": function () {
      this.$refs.HerhaalWachtwoord.error = false;
      this.$refs.HerhaalWachtwoord.errorMessages = "";
    },
    "userBestaand.Email": function () {
      this.bestaandInloggenMislukt = false;
    },
    "userBestaand.Wachtwoord": function () {
      this.bestaandInloggenMislukt = false;
    },
  },
  mounted() {
    this.loadData();
    if (this.$route.query.mode && this.$route.query.mode === "existing")
      this.step = 3;
    if (this.$route.query.mode && this.$route.query.mode === "new")
      this.step = 2;
  },
};
</script>
