<template>
  <div>
    <v-card v-if="gebruikers">
      <v-card-title
        >Gebruikers {{ profielinfo.Bedrijf }}<v-spacer></v-spacer>
      </v-card-title>
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-switch
          class="pa-2"
          v-model="alleenActief"
          hide-details
          label="Toon alleen actieve gebruikers"
        ></v-switch
      ></v-card-actions>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="gebruikersf"
          hide-default-footer
          disable-pagination
          disable-sort
          @click:row="editUser"
        >
          <template v-slot:[`item.Actief`]="{ item }">
            <span v-show="item.Actief">Actief</span>
            <span v-show="!item.Actief">Inactief</span>
          </template>
          <template v-slot:[`item.IsAdmin`]="{ item }">
            <span v-show="item.IsAdmin">Beheerder</span>
            <span v-show="!item.IsAdmin">Gebruiker</span>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn color="secondary" @click="addUser()"
          ><v-icon left>mdi-plus</v-icon> Nieuwe gebruiker</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="add.showDialog" max-width="500">
      <v-card v-if="add.editobj"
        ><v-card-title>Nieuwe gebruiker</v-card-title
        ><v-card-text>
          <v-form v-model="add.valid" ref="formAdd">
            <v-text-field
              label="Naam"
              autofocus
              prepend-icon="mdi-account-circle"
              maxlength="200"
              v-model="add.editobj.Naam"
              validate-on-blur
              :rules="[(v) => !!v || 'Vul een naam in.']"
            ></v-text-field>
            <v-text-field
              label="E-mail"
              prepend-icon="mdi-email"
              maxlength="500"
              v-model="add.editobj.Email"
              validate-on-blur
              :rules="[
                (v) => !!v || 'Vul een emailadres in.',
                (v) =>
                  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                  'Vul geldig emailadres in',
              ]"
            ></v-text-field>
            <v-checkbox
              label="Beheerder"
              prepend-icon="mdi-security"
              v-model="add.editobj.IsAdmin"
            >
            </v-checkbox>
            <p>De nieuwe gebruiker ontvangt een mail met instructies.</p>
          </v-form> </v-card-text
        ><v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="saveNewUser" color="secondary">Opslaan</v-btn
          ><v-btn @click="add.showDialog = false"
            >Annuleren</v-btn
          ></v-card-actions
        ></v-card
      ></v-dialog
    >

    <v-dialog v-model="edit.showDialog" max-width="500">
      <v-card v-if="edit.editobj"
        ><v-card-title
          >Bewerken gebruiker<v-spacer></v-spacer
          ><v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon color="primary">
                <v-icon>mdi-chevron-down-circle</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="deleteUser">
                <v-list-item-title>Verwijder gebruiker</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu></v-card-title
        ><v-card-text>
          <v-form v-model="edit.valid" ref="formEdit">
            <v-text-field
              label="Naam"
              autofocus
              prepend-icon="mdi-account-circle"
              maxlength="200"
              v-model="edit.editobj.Naam"
              validate-on-blur
              :rules="[(v) => !!v || 'Vul een naam in.']"
            ></v-text-field>
            <v-checkbox
              label="Beheerder"
              prepend-icon="mdi-security"
              v-model="edit.editobj.IsAdmin"
            >
            </v-checkbox>
            <v-radio-group v-model="edit.editobj.Active" label="Status">
              <v-radio label="Actief" :value="true"></v-radio>
              <v-radio label="Inactief" :value="false"></v-radio>
            </v-radio-group> </v-form></v-card-text
        ><v-card-actions
          ><v-spacer></v-spacer
          ><v-btn @click="saveUser" color="secondary">Opslaan</v-btn
          ><v-btn @click="edit.showDialog = false"
            >Annuleren</v-btn
          ></v-card-actions
        ></v-card
      ></v-dialog
    >
  </div>
</template>
<script>
export default {
  name: "Gebruikers",
  components: {},
  props: [],
  data: () => ({
    gebruikers: [],
    profielinfo: {},
    headers: [
      { text: "Naam", value: "Naam" },
      { text: "E-mail", value: "Email" },
      { text: "Status", value: "Actief" },
      { text: "Aantal bewijzen", value: "AantalBewijzen" },
      { text: "Rol", value: "IsAdmin" },
    ],
    alleenActief: false,
    add: {
      showDialog: false,
      editobj: null,
      valid: true,
    },
    edit: {
      showDialog: false,
      editobj: null,
      valid: true,
    },
  }),
  methods: {
    async loadData() {
      this.getGebruikers();
      this.profielinfo = (await this.$api().UserAdmin_pProfielInfo()).data;
    },
    async getGebruikers() {
      this.gebruikers = (await this.$api().UserAdmin_pGetUsers()).data;
    },
    addUser() {
      this.add.showDialog = true;
      this.add.valid = true;
      this.add.editobj = { Id: null, Naam: "", Email: "", IsAdmin: false };
      this.$nextTick(function () {
        this.$refs.formAdd.resetValidation();
      });
    },
    async saveNewUser() {
      if (this.$refs.formAdd.validate()) {
        try {
          let result = (
            await this.$api().Bedrijf_pSaveNewUser(this.add.editobj)
          ).data;
          if (!result.Success) {
            await this.$confirm(
              "De gebruiker kon niet aangemaakt worden. " + result.Message,
              {
                buttonFalseText: "Sluiten",
                buttonTrueText: null,
              }
            );
          } else {
            this.getGebruikers();
            this.add.showDialog = false;
          }
        } catch (ex) {
          await this.$confirm("Fout bij aanmaken gebruiker. " + ex.toString(), {
            buttonFalseText: "Sluiten",
            buttonTrueText: null,
          });
        }
      }
    },
    async editUser(u) {
      this.edit.showDialog = true;
      this.edit.valid = true;
      try {
        this.edit.editobj = (
          await this.$api().Bedrijf_pGetUser({ UserId: u.Id })
        ).data;
      } catch (ex) {
        await this.$confirm("Fout: " + ex.toString(), {
          buttonFalseText: "Sluiten",
          buttonTrueText: null,
        });
      }
      this.$nextTick(function () {
        this.$refs.formEdit.resetValidation();
      });
    },
    async saveUser() {
      if (this.$refs.formEdit.validate()) {
        try {
          let res = (await this.$api().Bedrijf_pSaveUser(this.edit.editobj))
            .data;
          if (!res.Success) {
            await this.$confirm("Fout: " + res.Message, {
              buttonFalseText: "Sluiten",
              buttonTrueText: null,
            });
          } else {
            this.getGebruikers();
            this.edit.showDialog = false;
          }
        } catch (ex) {
          await this.$confirm("Opslaan niet mogelijk. " + ex.toString(), {
            buttonFalseText: "Sluiten",
            buttonTrueText: null,
          });
        }
      }
    },
    async deleteUser() {
      if (
        !(await this.$confirm(
          "Wil je deze gebruiker inclusief alle gegevens verwijderen?"
        ))
      )
        return;
      if (
        !(await this.$confirm(
          "Weet je zeker dat je deze gebruiker wilt verwijderen? Dit kan niet ongedaan gemaakt worden."
        ))
      )
        return;
      try {
        let result = (
          await this.$api().Bedrijf_pDeleteUser({
            UserId: this.edit.editobj.Id,
          })
        ).data;
        if (result.Success) {
          this.getGebruikers();
          this.edit.showDialog = false;
        } else {
          await this.$confirm(
            "Fout bij verwijderen gebruiker: " + result.Message,
            {
              buttonFalseText: "Sluiten",
              buttonTrueText: null,
            }
          );
        }
      } catch (ex) {
        await this.$confirm(
          "Fout bij verwijderen gebruiker: " + ex.toString(),
          {
            buttonFalseText: "Sluiten",
            buttonTrueText: null,
          }
        );
      }
    },
  },
  computed: {
    gebruikersf: function () {
      if (this.alleenActief) return this.gebruikers.filter((c) => c.Actief);
      else return this.gebruikers;
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>
