var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.step == 1)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Welkom bij Bouwbewijs")]),_c('v-card-text',[_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","outlined":""},on:{"click":_vm.inloggenBestaand}},[_c('v-card-title',[_vm._v("Inloggen")])],1),_c('v-card',{staticClass:"mb-4",attrs:{"elevation":"2","outlined":""},on:{"click":_vm.aanmelden}},[_c('v-card-title',[_vm._v("Nieuw account aanmaken")])],1)],1)],1):_vm._e(),(_vm.step == 2)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Aanmelden bij Bouwbewijs")]),_c('v-card-text',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Bedrijfsnaam","prepend-icon":"mdi-office-building-outline","autofocus":"","maxlength":"200"},model:{value:(_vm.user.Bedrijf),callback:function ($$v) {_vm.$set(_vm.user, "Bedrijf", $$v)},expression:"user.Bedrijf"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Naam*","prepend-icon":"mdi-account-circle","maxlength":"200","required":"","rules":[(v) => !!v || 'Vul je naam in.']},model:{value:(_vm.user.Naam),callback:function ($$v) {_vm.$set(_vm.user, "Naam", $$v)},expression:"user.Naam"}}),_c('v-text-field',{ref:"email",staticClass:"mb-2",attrs:{"type":"email","label":"Emailadres*","prepend-icon":"mdi-email","maxlength":"200","validate-on-blur":"","required":"","autocomplete":"username","rules":[
            (v) => !!v || 'Vul je emailadres in.',
            (v) =>
              /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
              'Vul geldig emailadres in',
          ]},model:{value:(_vm.user.Email),callback:function ($$v) {_vm.$set(_vm.user, "Email", $$v)},expression:"user.Email"}}),_c('v-text-field',{ref:"Wachtwoord",staticClass:"mb-2",attrs:{"label":"Wachtwoord*","type":"password","prepend-icon":"mdi-lock","maxlength":"200","required":"","validate-on-blur":"","autocomplete":"new-password","rules":[
            (v) => !!v || 'Vul een wachtwoord in',
            (v) => /^.{8,}$/.test(v) || 'Minimaal 8 posities',
          ]},model:{value:(_vm.user.Wachtwoord),callback:function ($$v) {_vm.$set(_vm.user, "Wachtwoord", $$v)},expression:"user.Wachtwoord"}}),_c('v-text-field',{ref:"HerhaalWachtwoord",staticClass:"mb-2",attrs:{"label":"Herhaal wachtwoord*","type":"password","prepend-icon":"mdi-lock","maxlength":"200","required":"","autocomplete":"new-password"},model:{value:(_vm.user.WachtwoordHerhaal),callback:function ($$v) {_vm.$set(_vm.user, "WachtwoordHerhaal", $$v)},expression:"user.WachtwoordHerhaal"}}),_c('v-checkbox',{attrs:{"rules":[
          (v) =>
            !!v ||
            'Je kunt alleen een account aanmaken indien je akkoord gaat met de voorwaarden.',
        ]},model:{value:(_vm.akkoord),callback:function ($$v) {_vm.akkoord=$$v},expression:"akkoord"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Ik ga akkoord met de "),_c('a',{attrs:{"href":"https://www.bouwbewijs.nl/gebruiksvoorwaarden","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v("gebruiksvoorwaarden")])])])],1)],1),_c('v-card-actions',[_c('v-btn',{on:{"click":function($event){_vm.step = 1}}},[_vm._v("Annuleren")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.accountAanmaken}},[_vm._v("Account aanmaken")])],1)],1):_vm._e(),(_vm.step == 3)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v("Aanmelden bij Bouwbewijs")]),_c('v-card-text',[_c('v-form',{ref:"formBestaandAccount",model:{value:(_vm.validBestaandAccount),callback:function ($$v) {_vm.validBestaandAccount=$$v},expression:"validBestaandAccount"}},[_vm._v(" Inloggen met bestaand account "),_c('v-text-field',{ref:"emailBestaand",staticClass:"mb-2",attrs:{"label":"Emailadres*","type":"email","prepend-icon":"mdi-email","maxlength":"200","autofocus":"","autocomplete":"username","required":"","rules":[(v) => !!v || 'Vul je emailadres in.']},model:{value:(_vm.userBestaand.Email),callback:function ($$v) {_vm.$set(_vm.userBestaand, "Email", $$v)},expression:"userBestaand.Email"}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"label":"Wachtwoord*","type":"password","prepend-icon":"mdi-lock","maxlength":"200","required":"","autocomplete":"current-password","rules":[
            (v) => !!v || 'Vul een wachtwoord in',
            (v) => /^.{8,}$/.test(v) || 'Minimaal 8 posities',
          ]},model:{value:(_vm.userBestaand.Wachtwoord),callback:function ($$v) {_vm.$set(_vm.userBestaand, "Wachtwoord", $$v)},expression:"userBestaand.Wachtwoord"}}),_c('v-btn',{attrs:{"text":"","small":"","to":{ name: 'passwordreset' }}},[_vm._v("Wachtwoord vergeten")]),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.bestaandInloggenMislukt),expression:"bestaandInloggenMislukt"}],attrs:{"color":"error","text":""}},[_vm._v("Inloggen mislukt")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.step = 1}}},[_vm._v("Annuleren")]),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.inloggenBestaandAccount}},[_vm._v("Inloggen")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }