<template>
  <div>
    <v-card v-if="licentiedata && !licentiedata.Opgezegd && licentiedata.IsPro">
      <v-card-title
        >Licentie opzeggen <v-spacer></v-spacer>
        <v-btn icon title="Terug" :to="{ name: 'licentie' }" plain
          ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        <h3>
          <v-icon>mdi-emoticon-sad-outline</v-icon> Jammer dat je weg gaat.
        </h3>
        <p class="mt-3">
          Wil je je PRO-licentie opzeggen per
          <b>{{ licentiedata.VerlengenPer }}</b
          >?
        </p>
        <p>
          Je ontvangt een bevestigingsmail van je opzegging op
          {{ licentiedata.Email }}.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" @click="opzeggen()">Ja</v-btn>
        <v-btn :to="{ name: 'licentie' }">Nee</v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="licentiedata && (licentiedata.Opgezegd || licentiedata.IsDemo)"
    >
      <v-card-title
        >Licentie opzeggen <v-spacer></v-spacer>
        <v-btn icon title="Terug" :to="{ name: 'licentie' }" plain
          ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
        ></v-card-title
      >
      <v-card-text>
        Je licentie is al opgezegd of je hebt geen licentie.
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "Opzeggen",
  components: {},
  props: [],
  data: () => ({
    licentiedata: null,
  }),
  methods: {
    loadData() {
      this.$api()
        .Licentie_pGetLicentieData()
        .then((response) => {
          this.licentiedata = response.data;
        })
        .catch(() => {});
    },
    opzeggen() {
      this.$api()
        .Licentie_pOpzeggen()
        .then(() => {
          this.$router.push({ name: 'licentie' });
        })
        .catch(() => {});
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>
