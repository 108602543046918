<template>
  <v-card>
    <v-card-title
      >Facturen<v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'licentie' }" plain
        ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="facturen"
        :disable-pagination="true"
        :hide-default-footer="true"
        :disable-sort="true"
        class="elevation-1"
        @click:row="openFactuur"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Facturen",
  components: {},
  props: [],
  data: () => ({
    headers: [
      { text: "Factuurnr.", value: "Factuurnummer" },
      { text: "Licentie", value: "Omschrijving" },
      { text: "Datum", value: "Factuurdatum" },
      { text: "Bedrag", value: "BedragInBtw", align: "end" },
    ],
    facturen: [],
  }),
  methods: {
    loadData() {
      this.$api()
        .Factuur_pFacturenGet()
        .then((response) => {
          this.facturen = response.data;
        })
        .catch(() => {});
    },
    openFactuur(factuur) {
      this.$router.push({
        name: "factuur",
        params: { factuurnummer: factuur.Factuurnummer },
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>
