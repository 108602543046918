<template>
  <v-card v-if="licentiedata">
    <v-card-title
      >Licentie<v-spacer></v-spacer
      ><v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-show="!licentiedata.IsBedrijfsaccount"
            v-bind="attrs"
            v-on="on"
            icon
            color="primary"
          >
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="facturen()">
            <v-list-item-title>Facturen</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="opzeggen()"
            v-show="!licentiedata.Opgezegd && licentiedata.IsPro"
          >
            <v-list-item-title>Licentie opzeggen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn icon title="Terug" :to="{ name: 'mijnbewijzen' }" plain
        ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-file-sign</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Licentietype</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.LicentieType.Omschrijving
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Email</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.Email
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>

        <v-list-item
          v-if="
            !licentiedata.IsBedrijfsaccount &&
            licentiedata.Betaalwijze &&
            licentiedata.Betaalwijze.Id &&
            !licentiedata.Opgezegd &&
            licentiedata.IsPro
          "
        >
          <v-list-item-icon><v-icon>mdi-currency-eur</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Betaalwijze</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.Betaalwijze.Omschrijving
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>

        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount && licentiedata.VolgendeIncasso
          "
        >
          <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Volgende betaling</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.VolgendeIncasso
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount &&
            licentiedata.IsPro &&
            !licentiedata.Opgezegd &&
            licentiedata.IsAutoIncasso
          "
        >
          <v-list-item-icon><v-icon>mdi-sine-wave</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Betalingsfrequentie</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.Betaalfrequentie.Omschrijving
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount &&
            licentiedata.IsPro &&
            !licentiedata.Opgezegd &&
            licentiedata.IsAutoIncasso
          "
        >
          <v-list-item-icon><v-icon>mdi-autorenew</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Automatisch verlengen per</v-list-item-title>
            <v-list-item-subtitle>{{
              licentiedata.VerlengenPer
            }}</v-list-item-subtitle></v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount &&
            licentiedata.IsPro &&
            !licentiedata.Opgezegd &&
            !licentiedata.IsAutoIncasso
          "
        >
          <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
          <v-list-item-content
            ><v-list-item-title>Geldig tot</v-list-item-title>
            <v-list-item-subtitle
              >{{ $dayjs(licentiedata.LicentieTot).format("DD-MM-YYYY")
              }}<span v-show="licentiedata.VerlengdTot">
                (verlengd tot
                {{
                  $dayjs(licentiedata.VerlengdTot).format("DD-MM-YYYY")
                }})</span
              ></v-list-item-subtitle
            ></v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-if="!licentiedata.IsBedrijfsaccount && !licentiedata.IsPro"
        >
          <v-list-item-content
            >Een PRO-licentie kost &euro;
            {{ licentiedata.Tarief.MaandTarief }} euro per maand of &euro;
            {{ licentiedata.Tarief.JaarTarief }} euro per jaar, exclusief
            BTW.</v-list-item-content
          >
        </v-list-item>
        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount &&
            !licentiedata.IsPro &&
            licentiedata.ProAangeschaftOp == null
          "
        >
          <v-list-item-content>
            <v-row>
              <v-col
                ><v-btn color="secondary" @click="goPro()"
                  >Vraag PRO aan</v-btn
                ></v-col
              >
              <v-col class="text--secondary"
                >Aanvragen is vrijblijvend. Je ontvangt een email met verdere
                informatie.</v-col
              >
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="
            !licentiedata.IsBedrijfsaccount &&
            licentiedata.ProAangeschaftOp != null
          "
        >
          <v-list-item-content>
            <v-row>
              <v-col class="text--secondary"
                >Je hebt een PRO licentie aangevraagd.
              </v-col>
              <v-col
                ><v-btn color="secondary" @click="goPro()" link
                  >Betaallink opnieuw verzenden</v-btn
                ></v-col
              >
            </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="!licentiedata.IsBedrijfsaccount && licentiedata.Opgezegd"
        >
          <v-list-item-content>
            Je licentie is opgezegd per
            {{ $dayjs(licentiedata.LicentieTot).format("DD-MM-YYYY") }}.
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-btn color="secondary" v-show="licentiedata.IsPro && mutaties"
        >Wijziging opslaan</v-btn
      >
      <v-btn
        color="secondary"
        v-show="!licentiedata.IsBedrijfsaccount && licentiedata.Opgezegd"
        @click="verlengPro"
        >Verleng PRO</v-btn
      >
      <v-dialog v-model="dialogAangevraagd">
        <v-card>
          <v-card-title class="text-h5 lighten-2"> Gelukt </v-card-title>
          <v-card-text>
            Bedankt voor het aanvragen van een PRO licentie. Je ontvangt een
            email met verdere informatie.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="dialogAangevraagdClick">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Licentie",
  components: {},
  props: [],
  data: () => ({
    licentiedata: null,
    dialogAangevraagd: false,
    dialogAanvraagMislukt: false,
    melding: "",
    orgfrequentie: null,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Licentie_pGetLicentieData()
        .then((response) => {
          this.licentiedata = response.data;
          if (this.licentiedata.Betaalfrequentie != null)
            this.orgfrequentie = this.licentiedata.Betaalfrequentie.Id;
        })
        .catch(() => {});
    },
    goPro: function () {
      this.$api()
        .Licentie_pMailPro()
        .then(() => {
          this.dialogAangevraagd = true;
        })
        .catch(() => {});
    },
    verlengPro: function () {
      this.$api()
        .Licentie_pAnnuleerOpzegging()
        .then(() => {
          this.loadData();
        })
        .catch(() => {});
    },
    facturen: function () {
      this.$router.push({ name: "facturen" });
    },
    opzeggen: function () {
      this.$router.push({ name: "licentieopzeggen" });
    },
    details: function () {
      this.$router.push({ name: "licentiedetails" });
    },
    dialogAangevraagdClick: function () {
      if (this.licentiedata.HeeftRol)
        this.$router.push({ name: "mijnbewijzen" });
      else this.dialogAangevraagd = false;
    },
  },
  watch: {},
  computed: {
    mutaties: function () {
      if (this.licentiedata == null) return false;
      return this.licentiedata.Betaalfrequentie.Id != this.orgfrequentie;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.actief {
  font-weight: bold;
  color: #e40429;
}
</style>
