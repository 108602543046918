<template>
  <div>
    <h1>Welkom bij bouwbewijs</h1>
    <p>
      Hier een beginpagina met notificaties, nieuws, entrypoints
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data: () => ({}),
  methods: {
    loadData: function () {},
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
