<template>
  <v-card flat>
    <v-card-title><span v-show="!shared">Tags</span><span v-show="shared">Gedeelde tags</span>
      <v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'instellingen' }" v-if="!selected && !shared" color="primary"
        plain><v-icon>mdi-close-circle</v-icon></v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon v-show="selected" color="primary">
            <v-icon>mdi-chevron-down-circle</v-icon>
          </v-btn>
        </template>
        <v-list v-if="selected">
          <v-list-item @click="archiveerTag(selected)" v-show="!selected.IsGearchiveerd">
            <v-list-item-title v-if="!shared">Tag archiveren</v-list-item-title>
            <v-list-item-title v-if="shared">Gedeelde tag archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dearchiveerTag(selected)" v-show="selected.IsGearchiveerd">
            <v-list-item-title>Tag de-archiveren</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteTag(selected)" v-show="selected.Id && !selected.InGebruik">
            <v-list-item-title v-if="!shared">Tag verwijderen</v-list-item-title>
            <v-list-item-title v-if="shared">Gedeelde tag verwijderen</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <div v-if="!selected">
        <v-btn color="secondary" class="mt-3 mb-3" @click="nieuw()" v-show="!shared">Nieuwe tag</v-btn>
        <v-btn color="secondary" class="mt-3 mb-3" @click="nieuw()" v-show="shared">Nieuwe gedeelde tag</v-btn>

        <v-list-item v-for="(t, i) in eigentags" :key="'e' + i" @click="selected = t">
          <v-list-item-icon>
            <v-icon>mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ t.Tekst }} ({{ t.AantalBewijzen }})</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <h3 text small @click="toonarchief = !toonarchief" v-show="archief.length > 0" class="mt-3"
          style="cursor: pointer">
          <v-icon v-show="toonarchief">mdi-chevron-up</v-icon><v-icon v-show="!toonarchief">mdi-chevron-down</v-icon>
          Gearchiveerde
          tags ({{ archief.length }})
        </h3>
        <div v-show="toonarchief">
          <v-list-item v-for="(t, i) in archief" :key="'e' + i" @click="selected = t">
            <v-list-item-icon>
              <v-icon>mdi-tag</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ t.Tekst }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </div>
      <v-form v-if="selected" ref="form" v-on:submit.prevent="saveTag(selected)">
        <v-text-field v-model="selected.Tekst" autofocus label="Tag" maxlength="200" required
          :rules="[(v) => !!v || 'Vul een tagnaam in.']"></v-text-field>
        <p v-show="selected.IsGearchiveerd == true">Tag is gearchiveerd</p>
      </v-form>
    </v-card-text>
    <v-card-actions v-if="selected">
      <v-spacer></v-spacer>
      <v-btn color="secondary" @click="saveTag(selected)">Opslaan</v-btn>
      <v-btn @click="selected = null">Annuleren</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Tagbeheer",
  components: {},
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tags: [],
    selected: null,
    toonarchief: false,
  }),
  methods: {
    loadData: async function () {

      if (this.shared) {
        // Shared bedrijfstags
        try {
          this.tags = (await this.$api().Instellingen_pTagsSharedGet()).data;
        } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
      } else {
        try {
          // Persoonlijke tags
          this.tags = (await this.$api().Instellingen_pTagsGet()).data;
        } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
      }
    },
    saveTag: function (tag) {
      if (this.$refs.form.validate()) {
        this.$api()
          .Tags_pTagSave(tag)
          .then(() => {
            this.selected = null;
            this.loadData();
          })
          .catch(() => {
            this.selected = null;
          });
      }
    },
    nieuw: function () {
      this.selected = {
        Id: null,
        Tekst: "",
        IsGearchiveerd: false,
        TagTypeId: this.shared ? 3 : 1
      };
    },
    deleteTag: function (tag) {
      this.$confirm("Wil je deze tag verwijderen?").then((res) => {
        if (res) {
          this.$api()
            .Tags_pTagDelete({ TagId: tag.Id })
            .then(() => {
              this.selected = null;
              this.loadData();
            })
            .catch(() => { });
        }
      });
    },
    archiveerTag: async function (tag) {
      const res = await this.$confirm("Wil je deze tag archiveren?");
      if (res) {
        tag.IsGearchiveerd = true;
        this.saveTag(tag);
      }
    },
    dearchiveerTag: async function (tag) {
      const res = await this.$confirm("Wil je deze tag de-archiveren?");
      if (res) {
        tag.IsGearchiveerd = false;
        this.saveTag(tag);
      }
    },
  },
  computed: {
    eigentags: function () {
      return this.tags.filter((c) => c.IsGearchiveerd == false);
    },
    archief: function () {
      return this.tags.filter((c) => c.IsGearchiveerd == true);
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
