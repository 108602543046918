<template>
  <v-card flat>
    <v-card-title><span v-show="!shared">Bewijstypes</span><span v-show="shared">Gedeelde
        bewijstypes</span><v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'instellingen' }" plain v-if="!shared"><v-icon
          color="primary">mdi-close-circle</v-icon></v-btn></v-card-title>
    <v-card-text>
      <v-subheader v-if="!shared">Eigen bewijstypes</v-subheader>
      <v-subheader v-if="shared">Gedeelde bewijstypes</v-subheader>
      <v-btn color="secondary" class="ml-4 mb-3" :to="{ name: shared ? 'kaarttypenewbedrijf' : 'kaarttypenew' }">Nieuw
        bewijstype</v-btn>
        <div class="ml-4" v-show="shared"><em>Zelf een bewijstype maken</em></div>
      <v-list-item v-for="(k, i) in eigenkaarttypes" :key="'k' + i"
        :to="{ name: shared ? 'kaarttypeeditbedrijf' : 'kaarttypeedit', params: { id: k.Id } }">
        <v-list-item-icon>
          <v-icon :color="k.Kleur">mdi-card-text-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ k.Omschrijving }} ({{ k.AantalBewijzen }})</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-alert type="info" text v-show="kaarttypes.length > 0">
        Tussen haakjes staat het aantal bewijzen van dit bewijstype.
      </v-alert>
      <v-btn text small @click="toonarchief = !toonarchief" v-show="archief.length > 0">
        <v-icon v-show="toonarchief">mdi-chevron-up</v-icon><v-icon v-show="!toonarchief">mdi-chevron-down</v-icon>
        Gearchiveerde
        bewijstypes ({{ archief.length }})</v-btn>
      <div v-show="toonarchief">
        <v-list-item v-for="(k, i) in archief" :key="'k' + i"
          :to="{ name: shared ? 'kaarttypeeditbedrijf' : 'kaarttypeedit', params: { id: k.Id } }">
          <v-list-item-icon>
            <v-icon :color="k.Kleur">mdi-card-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ k.Omschrijving }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="!shared">
        <v-subheader>Bewijstypes uit catalogus</v-subheader>
        <v-btn color="secondary" class="ml-4 mb-3" :to="{ name: 'catalogus' }">Catalogus</v-btn>
        <v-list-item v-for="(g, i) in kaarttypesCata" :key="'g' + i" :to="{
          name: 'catalogusitem',
          params: { id: g.PubId },
          query: { returnpath: 'kaarttypebeheer' },
        }">
          <v-list-item-icon>
            <v-icon :color="g.Kleur">mdi-card-text-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ g.Omschrijving }} ({{ g.AantalBewijzen }})</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div v-if="shared">
        <v-btn color="secondary" class="ml-4 mb-3 mt-3" :to="{ name: 'bedrijfscatalogus' }">Bewijstype uit de catalogus </v-btn>
        <div class="ml-4"><em>Een bewijstype gebruiken uit de catalogus. Deze kan na toevoegen bewerkt worden.</em></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "KaarttypeBeheer",
  props: {
    shared: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: () => ({ kaarttypes: [], kaarttypesCata: [], toonarchief: false }),
  methods: {
    loadData: async function () {

      if (this.shared) {
        try {
          this.kaarttypes = (await this.$api().Instellingen_pKaarttypeSharedGet()).data;
        } catch (ex) { await this.$confirm("Fout: " + ex.toString(), { buttonFalseText: "Sluiten", buttonTrueText: null }); }
      } else {
        this.$api()
          .Instellingen_pKaarttypesGet()
          .then((response) => {
            this.kaarttypes = response.data;
          })
          .catch(() => { });
      }
      this.$api()
        .Instellingen_pKaarttypeCatalogus()
        .then((response) => {
          this.kaarttypesCata = response.data;
        })
        .catch(() => { });
    },
  },
  watch: {},
  computed: {
    eigenkaarttypes: function () {
      return this.kaarttypes.filter((c) => c.IsGearchiveerd == false);
    },
    archief: function () {
      return this.kaarttypes.filter((c) => c.IsGearchiveerd == true);
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
