<template>
    <v-card flat>
        <v-card-title>Voorblad rapport</v-card-title>
        <v-card-text>
            <v-form ref="formVerzenden">
                <v-text-field label="Titel rapport" v-model="voorbladLocal.titel" maxlength="200" required 
                    :rules="[(v) => !!v || 'Vul de titel van het rapport in.']"></v-text-field>
                <DatePicker label="Datum" v-model="voorbladLocal.datum"></DatePicker>
                <v-textarea label="Toelichting" v-model="voorbladLocal.toelichting" filled flat></v-textarea>
                <h2 class="mb-2">Rapport verzenden</h2>
                <v-combobox label="Aan (emailadres)" type="email" v-model="voorbladLocal.mailnaar" ref="aan"
                    validate-on-blur :items="adresboek" item-text="Email" item-value="Email" chips multiple deletable-chips
                    solo :delimiters="[',', ';', ' ']" :return-object="false" hide-selected
                    :menu-props="{ closeOnClick: true, closeOnContentClick: true }" :error-messages="aanErrors"
                    :error="error"></v-combobox>
                <v-text-field label="Van" readonly v-model="voorbladLocal.van" v-show="false"></v-text-field>
                <v-checkbox label="Stuur een kopie naar mijzelf (cc)" v-model="voorbladLocal.ccnaarmij"></v-checkbox>
                <v-text-field label="Onderwerp" readonly v-model="onderwerp" v-show="false"></v-text-field>
                <v-checkbox label="Geselecteerde bewijzen gelijk archiveren"
                    v-model="voorbladLocal.archiveren"></v-checkbox>

            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="terug()">Terug</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="verder()" v-show="!bezig">Rapport verzenden</v-btn><v-progress-circular
                v-show="bezig" indeterminate color="secondary"></v-progress-circular>
        </v-card-actions>
    </v-card>
</template>
  
<script>
import DatePicker from "@/components/common/date-picker.vue";

export default {
    name: "BedrijfsrapportVerzenden",
    components: { DatePicker },
    props: ["voorblad"],
    data: () => ({ adresboek: [], bezig: false }),
    model: {
        prop: "voorblad",
        event: "opslaanVoorblad",
    },
    methods: {
        loadData: function () {
            this.$api()
                .Adresboek_pGet()
                .then((response) => {
                    this.adresboek = response.data;
                })
                .catch(() => { });
        },
        verder: function () {
            if (!this.error && this.$refs.formVerzenden.validate()) {
                this.$emit("verder");
            }
        },
        terug: function () {
            this.$emit("terug");
        },
    },
    computed: {
        voorbladLocal: {
            get: function () {
                return this.voorblad;
            },
            set: function (value) {
                this.$emit("opslaanVoorblad", value);
            },
        },
        onderwerp: function () {
            return this.voorbladLocal.titel;
        },
        toelichtingNewLine: function () {
            if (this.voorblad.toelichting) return this.voorblad.toelichting;
            //.replace(/(?:\r\n|\r|\n)/g, '<br/>');
            else return null;
        },
        aanErrors: function () {
            const errors = [];
            if (
                !this.voorbladLocal.ccnaarmij &&
                this.voorbladLocal.mailnaar.length === 0
            )
                errors.push("Vul een emailadres in...");
            return errors;
        },
        error: function () {
            return (
                !this.voorbladLocal.ccnaarmij &&
                this.voorbladLocal.mailnaar.length === 0
            );
        },
    },
    watch: {},
    mounted() {
        this.loadData();
    },
};
</script>
  
<style scoped></style>
  