<template>
  <v-card>
    <v-card-title>Afmelden</v-card-title>
    <v-card-text>
      <div v-show="!verzonden">Klik op "Afmelden" om af te melden van automatische mails van Bouwbewijs</div><v-btn
        v-show="!verzonden" @click="verzend" class="mt-2">Afmelden</v-btn>
      <span v-show="verzonden">Je bent afgemeld van automatische mails van Bouwbewijs.</span>
    </v-card-text>
  </v-card>
</template>
  
<script>

export default {
  name: "Unsubscribe",
  data() {
    return {
      verzonden: false
    };
  },
  methods: {
    verzend: function () {
      this.$api()
        .UserAdmin_pUnsubscribe({ "Code": this.$route.query.code })
        .then((response) => {
          this.wat = response.data;
          this.verzonden = true;
        })
        .catch(() => { });
    }
  },
  created: function () {

  },
};
</script>
  
<style scoped></style>
  