<template>
  <div>
    <RapportSelectie
      titel="Selecteer bewijzen voor rapport"
      v-model="rapportparams.bewijzen"
      v-show="step == 1"
      @verder="selecteerBewijzen"
    ></RapportSelectie>
    <GeselecteerdeBewijzen
      v-show="step == 2"
      :bewijzen="rapportparams.bewijzen"
      @verder="step = 3"
      @toevoegen="step = 1"
    ></GeselecteerdeBewijzen>
    <Voorblad
      v-show="step == 3"
      v-model="rapportparams.voorblad"
      @verder="naarVerzendscherm"
      @terug="terug"
    ></Voorblad>
    <Verzenden
      v-show="step == 4"
      v-model="rapportparams.voorblad"
      @verder="verzenden"
      @terug="terug"
      ref="verzenden"
    ></Verzenden>
    <v-alert type="error" text border="top" v-show="error != null">{{
      error
    }}</v-alert>
    <v-snackbar v-model="verzonden" :timeout="3000" top color="success"
      >Rapport is verzonden.</v-snackbar
    >
  </div>
</template>

<script>
import RapportSelectie from "@/components/rapporten/RapportSelectie.vue";
import GeselecteerdeBewijzen from "@/components/rapporten/GeselecteerdeBewijzen.vue";
import Voorblad from "@/components/rapporten/Voorblad.vue";
import Verzenden from "@/components/rapporten/Verzenden.vue";

export default {
  name: "Rapporten",
  components: { RapportSelectie, GeselecteerdeBewijzen, Voorblad, Verzenden },
  data: () => ({
    step: 1,
    rapportparams: {
      bewijzen: [],
      voorblad: {
        titel: "",
        datum: new Date(),
        mailnaar: [],
        ccnaarmij: false,
        vanNaam: "",
        toelichting: "",
        mailtext: "",
        van: "",
        archiveren: false,
      },
    },
    error: null,
    verzonden: false,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Common_pUserInfo()
        .then((response) => {
          this.rapportparams.voorblad.vanNaam = response.data.Name;
          this.rapportparams.voorblad.van =
            response.data.Name + " <" + response.data.Email + ">";
          this.rapportparams.voorblad.toelichting =
            "Dit Bouwbewijs rapport is gemaakt door " + response.data.Name;
          if (response.data.Bedrijfsnaam && response.data.Bedrijfsnaam != "")
            this.rapportparams.voorblad.toelichting +=
              " van " + response.data.Bedrijfsnaam;
          this.rapportparams.voorblad.titel =
            "Bouwbewijs rapport " + this.$dayjs().format("D-M-YYYY_HHumm");
        })
        .catch(() => {});
    },
    selecteerBewijzen: function () {
      this.step++;
    },
    naarVerzendscherm: function () {
      this.setMailtext();
      this.step = 4;
    },
    verzenden: function () {
      this.error = null;
      this.$refs.verzenden.bezig = true;
      this.$api()
        .Rapporten_pGenereerRapport(this.rapportparams, "mailpdf")
        .then((response) => {
          this.$refs.verzenden.bezig = false;
          if (!response.data.Success) {
            this.error = response.data.Message;
          } else {
            // Verzenden succesvol
            // Adres toevoegen aan adresboek
            this.$api().Adresboek_pSave({
              Email: this.rapportparams.voorblad.mailnaar,
            });
            // Bewijzen archiveren
            if (this.rapportparams.voorblad.archiveren) {
              var archiveren = this.rapportparams.bewijzen
                .filter((c) => {
                  return c.Checked == true;
                })
                .map((c) => {
                  return { PubId: c.PubId };
                });
              this.$confirm(
                "Het rapport is verzonden. De gerapporteerde bewijzen (" +
                  archiveren.length.toString() +
                  ") archiveren?"
              ).then((res) => {
                if (res) {
                  this.$api()
                    .Archiveren_pArchiveerKaarten(archiveren)
                    .then(() => {
                      this.$router.push({ name: "mijnbewijzen" });
                    })
                    .catch(() => {});
                } else {
                  this.$router.push({ name: "mijnbewijzen" });
                }
              });
            } else {
              this.$router.push({ name: "mijnbewijzen" });
            }
          }
        })
        .catch(() => {
          this.$refs.verzenden.bezig = false;
        });
    },
    terug: function () {
      this.step--;
      this.error = null;
    },
    setMailtext: function () {
      this.rapportparams.voorblad.mailtext =
        "Hierbij ontvang je een rapport van Bouwbewijs.<br/><br/><em>Titel: " +
        this.rapportparams.voorblad.titel +
        "</em><br/>";
      if (
        this.rapportparams.voorblad.toelichting &&
        this.rapportparams.voorblad.toelichting.trim() != ""
      )
        this.rapportparams.voorblad.mailtext +=
          "<em>Toelichting: " +
          this.rapportparams.voorblad.toelichting +
          "</em><br/>";
      this.rapportparams.voorblad.mailtext +=
        '<br/>Met vriendelijke groet,<br/>Bouwbewijs<br/><br/><a href="https://bouwbewijs.nl" target="_blank">bouwbewijs.nl</a>';
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
