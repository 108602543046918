<template>
  <v-card v-if="factuur">
    <v-card-title
      >Factuur {{ factuur.Factuurnummer }} <v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'facturen' }" plain
        ><v-icon color="primary">mdi-close-circle</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-list>
        <v-subheader>Klantgegevens</v-subheader>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              >{{ factuur.Bedrijfsnaam }}<br />t.a.v. {{ factuur.Naam }}<br />
              {{ factuur.Emailadres }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader>Factuurgegevens</v-subheader>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Factuurnummer</v-list-item-title>
            <v-list-item-subtitle>{{
              factuur.Factuurnummer
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Factuurdatum</v-list-item-title>
            <v-list-item-subtitle>{{
              factuur.Factuurdatum
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title>Omschrijving</v-list-item-title>
            <v-list-item-subtitle>{{
              factuur.Omschrijving
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Bedrag ex. btw</td>
                    <td style="text-align: right">{{ factuur.BedragExBtw }}</td>
                  </tr>
                  <tr>
                    <td>Btw {{ factuur.BtwTarief }}%</td>
                    <td style="text-align: right">{{ factuur.Btw }}</td>
                  </tr>
                  <tr>
                    <td>Bedrag incl. btw</td>
                    <td style="text-align: right">{{ factuur.BedragInBtw }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <p class="mt-3">Bouwbewijs is een product van Heidekracht B.V.</p>
            <v-img
              class="mt-3"
              src="/heidekracht.png"
              max-width="200"
              contain
            ></v-img>
            <p class="mt-3">
              Heidekracht B.V.<br />Waalstraat 1<br />4184CM Opijnen<br /><br />KvK-nummer:
              80688373<br />BTW-nummer: NL861763762B01
            </p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small text class="secondary" @click="mailPdf()"
          ><v-icon left>mdi-email</v-icon> Email pdf</v-btn
        >
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "Factuur",
  components: {},
  props: [],
  data: () => ({
    factuur: null,
  }),
  methods: {
    loadData() {
      this.$api()
        .Factuur_pFactuurGet({
          Factuurnummer: this.$route.params.factuurnummer,
        })
        .then((response) => {
          this.factuur = response.data;
        })
        .catch(() => {});
    },
    async mailPdf ()  {
      const res = await this.$confirm(
        "Factuur verzenden naar " + this.factuur.Emailadres + "?"
      );
      if (res) {
        this.$api()
          .Factuur_pFactuurVerzendPdf(
            { Factuurnummer: this.$route.params.factuurnummer },
            "mailpdf"
          )
          .then(() => {})
          .catch(() => {});
      }
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>
