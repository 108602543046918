<template>
  <div v-if="licentiedata">
    <v-card v-if="licentiedata.Success && !licentiedata.NieuweLicentieMogelijk">
      <v-card-title class="justify-center"
        >Bouwbewijs PRO licentie</v-card-title
      >
      <v-card-text class="text-center">
        Deze licentie is al betaald.
      </v-card-text>
    </v-card>

    <v-card v-if="!licentiedata.Success">
      <v-card-title class="justify-center"
        >Bouwbewijs PRO licentie</v-card-title
      >
      <v-card-text class="text-center"> Ongeldige aanroep. </v-card-text>
    </v-card>

    <v-card v-if="licentiedata.Success && licentiedata.NieuweLicentieMogelijk">
      <v-card-title class="justify-center"
        >Bouwbewijs PRO licentie kopen</v-card-title
      >
      <v-card-text class="text-center" v-show="!busy">
        <v-form ref="form" v-model="valid">
          <h3>Voor Bouwbewijs account</h3>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Bedrijfsnaam</v-list-item-subtitle>
                <v-list-item-title>{{
                  licentiedata.Bedrijfsnaam
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Gebruikersnaam</v-list-item-subtitle>
                <v-list-item-title>{{ licentiedata.Naam }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>E-mail</v-list-item-subtitle>
                <v-list-item-title>{{ licentiedata.Email }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="licentiedata.LicentieType">
              <v-list-item-content>
                <v-list-item-subtitle>Huidige licentie</v-list-item-subtitle>
                <v-list-item-title>{{
                  licentiedata.LicentieType.Omschrijving
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <div class="text-center mt-6" v-if="licentiedata.Betaalfrequentie">
            <h2 class="mt-5">Bouwbewijs PRO licentie</h2>
            <v-list>
              <v-list-item style="justify-content: center">
                <v-switch
                  v-model="licentiedata.Betaalfrequentie.Id"
                  :true-value="2"
                  :false-value="1"
                >
                  <template #prepend>
                    <v-label
                      ><span
                        v-bind:class="{
                          actief: licentiedata.Betaalfrequentie.Id == 1,
                        }"
                        >Per&nbsp;maand</span
                      ></v-label
                    >
                  </template>
                  <template #append>
                    <v-label
                      ><span
                        v-bind:class="{
                          actief: licentiedata.Betaalfrequentie.Id == 2,
                        }"
                        >Per&nbsp;jaar</span
                      ></v-label
                    >
                  </template>
                </v-switch>
              </v-list-item>
            </v-list>
            <span
              style="position: relative; top: -16px"
              v-show="licentiedata.Betaalfrequentie.Id == 1"
              >tot en met
              <b>{{
                $dayjs(licentiedata.NieuweMaandLicentieTot).format("DD-MM-YYYY")
              }}</b></span
            >
            <span
              style="position: relative; top: -16px"
              v-show="licentiedata.Betaalfrequentie.Id == 2"
              >tot en met
              <b>{{
                $dayjs(licentiedata.NieuweJaarLicentieTot).format("DD-MM-YYYY")
              }}</b></span
            >
            <h2 class="mt-3">Betaalwijze</h2>
            <v-list-item
              style="justify-content: center"
              v-if="licentiedata.Betaalwijze"
            >
              <v-switch
                v-model="licentiedata.Betaalwijze.Id"
                :true-value="1"
                :false-value="2"
                v-show="licentiedata.Betaalfrequentie.Id == 2"
              >
                <template #prepend>
                  <v-label
                    ><span
                      v-bind:class="{
                        actief: licentiedata.Betaalwijze.Id == 2,
                      }"
                      >Automatische&nbsp;incasso</span
                    ></v-label
                  >
                </template>
                <template #append>
                  <v-label
                    ><span
                      v-bind:class="{
                        actief: licentiedata.Betaalwijze.Id == 1,
                      }"
                      >iDEAL</span
                    ></v-label
                  >
                </template>
              </v-switch>
              <v-label v-if="licentiedata.Betaalfrequentie.Id == 1"
                ><span class="actief">Automatische&nbsp;incasso</span></v-label
              >
            </v-list-item>

            <v-divider></v-divider>
            <h1
              class="mt-3 mb-5"
              v-show="licentiedata.Betaalfrequentie.Id == 2"
            >
              {{ licentiedata.Tarief.JaarTarief | toCurrency }}
              <small class="lblbtw">excl btw</small>
            </h1>
            <h1
              class="mt-3 mb-3"
              v-show="licentiedata.Betaalfrequentie.Id == 2"
            >
              {{ licentiedata.Tarief.JaarTariefIncl | toCurrency }}
              <small class="lblbtw">incl btw</small>
            </h1>
            <h1
              class="mt-3 mb-5"
              v-show="licentiedata.Betaalfrequentie.Id == 1"
            >
              {{ licentiedata.Tarief.MaandTarief | toCurrency }}
              <small class="lblbtw">excl btw</small>
            </h1>
            <h1
              class="mt-3 mb-3"
              v-show="licentiedata.Betaalfrequentie.Id == 1"
            >
              {{ licentiedata.Tarief.MaandTariefIncl | toCurrency }}
              <small class="lblbtw">incl btw</small>
            </h1>
          </div>
          <v-divider></v-divider>
          <v-list>
            <v-list-item style="justify-content: center">
              <v-checkbox
                v-model="akkoord"
                :rules="[
                  (v) =>
                    !!v ||
                    'Je kunt alleen een licentie kopen indien je akkoord gaat met de voorwaarden.',
                ]"
                ><div slot="label">
                  Akkoord met de
                  <a
                    href="https://www.bouwbewijs.nl/gebruiksvoorwaarden"
                    target="_blank"
                    @click.stop
                    >gebruiksvoorwaarden</a
                  >
                </div></v-checkbox
              >
            </v-list-item>
          </v-list>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn v-show="!busy" x-large color="secondary" @click="betalen()"
          >Naar betalen</v-btn
        >
        <span v-show="busy"
          ><v-progress-circular indeterminate></v-progress-circular> Je wordt doorgestuurd
          naar het betaalscherm...</span
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Licentie",
  components: {},
  props: [],
  data: () => ({
    licentiedata: null,
    akkoord: false,
    valid: true,
    busy: false,
  }),
  methods: {
    loadData: function () {
      this.$api()
        .Licentie_pGetLicentieData({ Usercode: this.$route.params.code })
        .then((response) => {
          this.licentiedata = response.data;
        })
        .catch(() => {});
    },
    betalen: function () {
      if (this.$refs.form.validate()) {
        let frequentie = "";
        let betaalwijze = "";
        if (this.licentiedata.Betaalfrequentie.Id == 1) frequentie = "maand";
        if (this.licentiedata.Betaalfrequentie.Id == 2) frequentie = "jaar";
        if (this.licentiedata.Betaalwijze.Id == 1) betaalwijze = "iDEAL";
        if (this.licentiedata.Betaalwijze.Id == 2)
          betaalwijze = "automatischeincasso";
        let url =
          process.env.VUE_APP_PAYMENT +
          "?gebruikergroepCode=" +
          this.licentiedata.Usercode +
          "&betaalfrequentie=" +
          frequentie +
          "&product=licentie-bouwbewijs" +
          "&betaalwijze=" +
          betaalwijze;
        this.busy = true;
        this.$api()
          .get(url)
          .then((response) => {
            this.busy = false;
            let checkouturl = response.data;
            window.location = checkouturl;
          })
          .catch(() => {
            this.busy = false;
            alert("Onbekende fout bij aanmaken betaallink.");
          });
      }
    },
  },
  watch: {
    "licentiedata.Betaalfrequentie.Id": function () {
      if (this.licentiedata.Betaalfrequentie.Id == 1)
        this.licentiedata.Betaalwijze.Id = 2;
    },
  },
  computed: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.actief {
  font-weight: bold;
  color: #e40429;
}
.lblbtw {
  font-size: 12pt;
}
</style>
