<template>
  <v-card flat>
    <v-card-title
      >Geselecteerde bewijzen <v-spacer></v-spacer
      ><v-chip color="secondary">{{ selcount }}</v-chip></v-card-title
    >
    <v-card-actions
      ><v-btn @click="extraBewijzen">Extra bewijzen</v-btn><v-spacer></v-spacer
      ><v-btn color="secondary" @click="verder"
        >Verder met selectie</v-btn
      ></v-card-actions
    >
    <v-card-text
      ><v-btn text small @click="deselect"
        >Alle getoonde bewijzen deselecteren</v-btn
      >

      <KaartComponent
        v-for="(k, i) in bewijzen"
        :key="'k' + i"
        :navigateTo="null"
        :kaart="k"
        class="mb-1"
        v-model="k.Checked"
      ></KaartComponent>
    </v-card-text>
  </v-card>
</template>

<script>
import KaartComponent from "@/components/mijnbewijzen/KaartComponent.vue";
export default {
  name: "GeselecteerdeBewijzen",
  components: { KaartComponent },
  props: ["bewijzen"],
  data: () => ({}),
  methods: {
    loadData: function () {},
    extraBewijzen: function () {
      this.$emit("toevoegen");
    },
    verder: async function () {
      if (this.selcount == 0) {
        const res = await this.$confirm(
          "Er zijn geen bewijzen geselecteerd. Weet je zeker dat je verder wilt gaan met 0 bewijzen?"
        );
        if (!res) {
          return;
        }
      }
      this.$emit("verder");
    },
    deselect: function () {
      this.bewijzen.forEach((c) => (c.Checked = false));
    },
  },
  computed: {
    selcount: function () {
      return this.bewijzen.filter((c) => c.Checked).length;
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
