import Vue from 'vue'
import VueApp from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import ApiHandler from './ApiHandler'
//import CmdApiHandler from './CmdApiHandler'
import './filters/currency'
import VuetifyConfirm from 'vuetify-confirm'
import Dayjs from 'vue-dayjs';
import CommonHelper from './helpers/common.js'
import { App } from '@capacitor/app';

Vue.config.productionTip = false
//Vue.prototype.$cmdapi = new CmdApiHandler();
Vue.prototype.$log = console.log
Vue.use(ApiHandler);


App.addListener('backButton', () => {
  router.push({ name: "mijnbewijzen" });
});

Vue.use(Dayjs, {
  lang: 'nl',
  filters: {
    ago: 'ago',
  },
  directives: {
    countdown: 'countdown',
  }
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'Ja',
  buttonFalseText: 'Nee',
  color: 'info',
  width: 350,
  property: '$confirm'
});

const helperplugin = {
  install() {
    Vue.helpers = CommonHelper
    Vue.prototype.$helpers = CommonHelper
  }
}
Vue.use(helperplugin)

Vue.prototype.$db = {};

new Vue({
  vuetify,
  router,
  render: h => h(VueApp)
}).$mount('#app')
