<template>
  <div>
    <div v-if="valueLocal != null">{{ label }} <v-icon v-show="clear" @click="valueLocal=null" style="position:relative; top:-2px">mdi-close-thick</v-icon></div>
    <v-expansion-panels :value="panel">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span v-if="valueLocal == null">{{ label }}</span>
          <span v-if="valueLocal != null">{{ valueLocal[itemText] }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item
              v-for="item in items"
              :key="item[itemValue]"
              @click="itemclick(item)"
            >
              <v-list-item-content>{{ item[itemText] }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
export default {
  name: "BobDropdown",
  components: {},
  props: {
    items: Array,
    label: String,
    itemText: String,
    itemValue: String,
    value: [Object, Number],
    clear: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    panel: null,
  }),
  model: {
    prop: "value",
    event: "valueChange",
  },
  methods: {
    itemclick: function (item) {
      if (this.returnObject)
        this.valueLocal = item;
      else
        this.valueLocal = item[this.itemValue];
      this.panel = [];
    },
  },
  computed: {
    valueLocal: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("valueChange", value);
      },
    },
  },
  watch: {},
  created() {},
};
</script>

<style scoped></style>
