<template>
  <v-card>
    <v-card-title>Gegevens wijzigen </v-card-title>
    <v-card-text v-if="profieldata">
      <v-form ref="form" v-model="valid">
        <v-text-field
          label="Bedrijfsnaam"
          v-model="profieldata.Bedrijf"
          maxlength="200"
        ></v-text-field>
        <v-text-field
          label="Naam"
          v-model="profieldata.Naam"
          maxlength="200"
          :rules="[(v) => !!v || 'Vul je naam in.']"
        ></v-text-field>
      </v-form>
      Wijzigen emailadres: Stuur een email met verzoek tot wijzigen van het
      emailadres naar
      <a href="mailto:bouwbewijs@heidekracht.nl">bouwbewijs@heidekracht.nl</a>.
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="{ name: 'account' }">Annuleren</v-btn>
      <v-btn color="secondary" @click="saveProfiel">Opslaan</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ProfielWijzigen",
  components: {},
  props: [],
  data: () => ({ profieldata: null, valid: true }),
  methods: {
    loadData: function () {
      this.$api()
        .UserAdmin_pProfielInfo()
        .then((response) => {
          this.profieldata = response.data;
        })
        .catch(() => {});
    },
    saveProfiel: function () {
      if (this.$refs.form.validate()) {
        this.$api()
          .UserAdmin_pProfielSave(this.profieldata)
          .then(() => {
            this.$router.push({ name: "account" });
          })
          .catch(() => {});
      }
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
