<template>
    <div v-cloak v-if="manual"></div>
</template> 
   
<script>
import { Capacitor } from "@capacitor/core";

export default {
    name: "Login",
    data() {
        return {
            loginOk: false,
            loginFailed: false,
            username: "",
            password: "",
            settings: null,
            errormessage: null,
            showerrormessage: false,
            manual: null,
        };
    },
    methods: {
        loaddata: function () { },
        redirect: function () {
            // Doorsturen naar ReturnUrl 
            const url = this.$route.query.ReturnUrl;
            if (url) {
                if (url == "/")
                    this.toStartPage();
                else
                    this.$router.push(url);
            } else {
                this.toStartPage();
            }
        },
        autoLogin: async function () {
            
            const online = true; //sessionStorage.getItem("online") == "true";

            let lsCred = JSON.parse(
                this.$helpers.crDec(localStorage.getItem("bouwbewijs-auth"))
            );

            if (lsCred && !online)
            {
                // De app is offline, maar in localStorage is gebruiker bekend.
                localStorage.setItem("username", lsCred.username);
                this.redirect();
            }

            if (lsCred) {
                this.$api()
                    .Login({ Username: lsCred.username, Password: lsCred.password })
                    .then((response) => {
                        // Inloggen gelukt 
                        sessionStorage.setItem("session-id", response.data.Token);
                        localStorage.setItem("username", lsCred.username);
                        this.$api().updateHeaders();
                        this.$root.$emit("login");
                        // Is licentie nog geldig? 
                        this.$api()
                            .Licentie_pLicGeldig()
                            .then((response) => {
                                if (response.data[0].Result) this.redirect(); // Licentie is geldig 
                                else this.$router.push({ name: "licentie" }); // Licentie is verlopen => naar licentiepagina 
                            })
                            .catch(() => { });
                    })
                    .catch(() => {
                        // Inloggen met gegevens uit localStorage niet gelukt => inlogscherm 
                        this.manual = true;
                        this.$router.push({ name: "aanmelden" });
                    });
            } else {
                this.manual = true;
                this.$router.push({ name: "aanmelden" });
            }
        },
        toStartPage: async function () {
            const online = sessionStorage.getItem("online") == "true";
            if (Capacitor.getPlatform() != "web")
                this.$router.push({ name: "mijnbewijzen" });
            if (!online) 
            {   // Niet online, dan nooit naar bedrijfsmode
                localStorage.removeItem("bedrijfsmode");
                this.$router.push({ name: "mijnbewijzen" });
            }
            let appinfo = (await this.$api().Common_pAppVersie()).data[0];
            if (!appinfo.Bedrijfsadmin)
                localStorage.removeItem("bedrijfsmode");
            const modelocstor = localStorage.getItem("bedrijfsmode");
            if (modelocstor != null) {
                if (modelocstor == "bedrijfsadmin")
                    this.$router.push({ name: "bedrijfbewijzen" });
                else
                    this.$router.push({ name: "mijnbewijzen" });
            }
            else {
                if (appinfo.Bedrijfsadmin)
                    this.$router.push({ name: "bedrijfbewijzen" });
                else
                    this.$router.push({ name: "mijnbewijzen" });
            }
        }
    },

    watch: {},
    mounted: function () {
        this.autoLogin();
    },
};
</script> 
   
<style scoped> p {
     line-height: 1.8;
 }
</style> 
   
<style> [v-cloak] {
     display: none;
 }
</style> 
  