<template>
  <v-card
    outlined
    :to="{ name: navigateTo, params: { id: kaart.PubId } }"
    :style="{
      borderLeftColor: leftBorderColor,
      borderLeftWidth: leftBorderWidth,
    }"
  >
    <v-card-title>
      {{ $dayjs(kaart.Datum).format("DD-MM-YYYY") }}
      {{ kaart.Kaarttype }}
      <v-spacer> </v-spacer>
      <v-checkbox
        @click.prevent="checkClick"
        v-show="checkedLocal != null"
        v-model="checkedLocal"
      ></v-checkbox>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6"
          ><v-img
            v-if="kaart.FotoPubId"
            :src="
              $api().getBaseUrl() +
              'ssKaart_pFoto?format=binary&PubId=' +
              kaart.FotoPubId
            "
            max-height="250"
            max-width="250"
          ></v-img
        ></v-col>
        <v-col cols="6"
          ><div class="mb-1">
            <v-chip
              v-for="(tag, i) in kaart.Tag"
              :key="'t' + i"
              class="mr-1 mb-1"
            >
              {{ tag.Tekst }}
            </v-chip>
          </div>
          <div v-show="kaart.Gebruiker">Gemaakt door: {{ kaart.Gebruiker }}</div>
          <span v-show="kaart.Gebruiker">Toelichting: </span>{{ kaart.VrijeTekst }}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "KaartComponent",
  components: {},
  props: ["navigateTo", "kaart", "checked"],
  data: () => ({}),
  methods: {
    loadData: function () {},
    checkClick: function () {},
  },
  watch: {},
  model: {
    prop: "checked",
    event: "checkedchange",
  },
  computed: {
    checkedLocal: {
      get: function () {
        return this.checked;
      },
      set: function (value) {
        this.$emit("checkedchange", value);
      },
    },
    leftBorderColor: function () {
      if (this.kaart.Kleur == "#FFFFFF") return "#E0E0E1";
      else return this.kaart.Kleur;
    },
    leftBorderWidth: function() {
      if (this.kaart.Kleur == "#FFFFFF") return "1px";
      else return "5px";
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
