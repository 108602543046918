<template>
  <v-card>
    <v-card-title>Info over Bouwbewijs<v-spacer></v-spacer>
      <v-btn icon title="Terug" :to="{ name: 'mijnbewijzen' }" plain><v-icon
          color="primary">mdi-close-circle</v-icon></v-btn></v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-information-outline </v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Versie</v-list-item-title>
            <v-list-item-subtitle>{{
              version
            }}<br />Netwerkstatus: <span v-show="online"><v-icon small color="green">mdi-circle</v-icon> online</span>
              <span v-show="!online"><v-icon small
                  color="red">mdi-circle</v-icon>offline</span></v-list-item-subtitle></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-web</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title><a href="https://bouwbewijs.nl"
                target="_blank">bouwbewijs.nl</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item><v-list-item-icon><v-icon>mdi-book-open-outline </v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title><a href="https://bouwbewijs.nl/instructies/"
                target="_blank">Instructies</a></v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title><a href="https://bouwbewijs.nl/gebruiksvoorwaarden/"
                target="_blank">Gebruiksvoorwaarden</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <p class="mt-3">
              Bouwbewijs is een product van
              <a href="https://heidekracht.nl" target="_blank">Heidekracht B.V.</a>
            </p>
            <v-img class="mt-3" src="/heidekracht.png" max-width="200" contain></v-img>
            <p class="mt-3">
              Heidekracht B.V.<br />Waalstraat 1<br />4184CM Opijnen<br /><br />KvK-nummer:
              80688373<br />BTW-nummer: NL861763762B01
            </p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to='{ name: "testdropdown" }' v-show="superadmin"><v-list-item-icon><v-icon>mdi-cog
            </v-icon></v-list-item-icon>
          <v-list-item-content><v-list-item-title>Testpagina</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { Network } from '@capacitor/network';

export default {
  name: "Info",
  components: {},
  props: [],
  data: () => ({ version: process.env.VUE_APP_VERSION, superadmin: false, online: true }),
  methods: {
    loadData: async function () {

      this.online = sessionStorage.getItem("online") == "true";
      if (this.online) {
        try {
          this.superadmin = (await this.$api().Common_pUserInfo()).data.SuperAdmin;
        } catch { this.superadmin = false; }
      }

      Network.addListener('networkStatusChange', status => {
        this.online = status.connected;
      });
    },
  },
  watch: {},
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
